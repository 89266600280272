import React, { Component } from 'react';
import buildCard from '../../spa-master/components/cards';
import WebApps from '../../spa-master/assets/scripts/master';

const { makeApiRequest } = WebApps.Requests;

class Partner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clients: [],
    };

    this.fetchData();
  }

  fetchData = () => {
    makeApiRequest(window.apiClient.clientsGet)
      .then((data) => {
        this.setState({
          clients: data.data,
        });
      })
      .catch(function () {
        window.Alert('error', 'Unable to fetch data!');
      });
  };

  render() {
    return buildCard(
      'dashboard',
      'Dashboard',
      <React.Fragment>
        <div className='simple--counter-container'>
          <div className='counter-container'>
            <div className='counter-content'>
              <h1 className='s-counter2 s-counter'>
                {this.state.clients.length}
              </h1>
            </div>
            <p className='s-counter-text'>Clients</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Partner;
