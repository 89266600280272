import { Component } from 'react';
import queryString from 'query-string';

import WebApps from '../assets/scripts/master';

const { makeApiRequest } = WebApps.Requests;

export default class Controller extends Component {
  constructor(props) {
    super(props);

    this.SET_GLOBAL_STATE = props.SET_GLOBAL_STATE;
    this.queryParams = queryString.parse(props.location.search);
    this.state = props.GLOBAL_STATE;
  }

  componentDidMount() {
    this.handleAction(this.queryParams.action);
  }

  handleAction = (action) => {
    this.SET_GLOBAL_STATE({
      PAGE_OPTIONS: {
        pageInfo: { pageTitle: 'Processing...' },
        showToolbar: false,
        showBreadcrumb: false,
      },
    });

    /**
     * The controller's responsibility is to handle actions that require
     * a redirect. We need to maintain the users current state manually because a
     * page refresh will destroy all the state.
     *
     * Before redirect, we are caching the users STATE
     */
    switch (action) {
      case 'enter_partner_portal':
        WebApps.URLs.redirect(
          '/',
          Object.assign({}, this.state, {
            USER_ID: localStorage.getItem('userId'),
            PARTNER_ID: parseInt(this.queryParams.partner_id),
            CLIENT_ID: 0,
            PORTAL_EMULATION_ACTIVE: true,
            PORTAL_TYPE: 'partner',
          })
        );

        break;

      case 'exit_partner_portal':
        WebApps.URLs.redirect(
          '/',
          Object.assign({}, this.state, {
            USER_ID: localStorage.getItem('userId'),
            PARTNER_ID: 0,
            CLIENT_ID: 0,
            PORTAL_EMULATION_ACTIVE: false,
            PORTAL_TYPE: 'global',
          })
        );
        break;

      case 'enter_client_portal':
        WebApps.URLs.redirect(
          '/',
          Object.assign({}, this.state, {
            USER_ID: localStorage.getItem('userId'),
            PARTNER_ID: localStorage.getItem('partnerId'),
            CLIENT_ID: parseInt(this.queryParams.client_id),
            PORTAL_EMULATION_ACTIVE: true,
            PORTAL_TYPE: 'client',
          })
        );

        break;

      case 'exit_client_portal':
        WebApps.URLs.redirect(
          '/',
          Object.assign({}, this.state, {
            USER_ID: localStorage.getItem('userId'),
            PARTNER_ID: localStorage.getItem('partnerId'),
            CLIENT_ID: 0,
            PORTAL_EMULATION_ACTIVE: false,
            PORTAL_TYPE: 'partner',
          })
        );

        break;

      case 'log_out':
        WebApps.Sessions.logOut();
        break;
      default:
        break;
    }
  };

  render() {
    return null;
  }
}
