import React, { Component } from 'react';
import WebApps from '../assets/scripts/master';
import Input from './Input';
import Button from './Buttons';

const { isset } = WebApps.Mappings;

/**
 * If you need to set default values for the form fields,
 * use the value key to indicate which dictionary key holds the value
 * in the original data source
 *
 * The requirement for this class is that you pass in the local SET_STATE
 * What needs to happen is that every time there is a change in the inputs,
 * that change is reflected in the parent. That way, when a button is clicked,
 * we can handle all the data from the parent (the actual page code).
 *
 * Set @id = database column
 * Set @value only if the incoming db column is different than the outbound
 * Set @data to store the schema of the form regardless of the inputs.
 */
export default class Form extends React.Component {
  constructor(props) {
    super(props);

    var formDataById = {};

    /**
     * Here we are defining the initial form by looping through
     * the inputs and storing any values or empty form fields
     * On component mount, we will set the parent state with
     * these values
     */
    Object.keys(props.inputs).map((inputId) => {
      formDataById[props.inputs[inputId]['name']] = isset(
        props.inputs[inputId],
        'value',
        ''
      );
    });

    props.SET_STATE({
      [props.dataId]: formDataById,
    });

    this.state = {
      id: props.id,
      formData: isset(props, 'data', {}),
      // We will store the data for this form in the a variable that is the unique name of the form 'action_resource_resourceId'
      [props.dataId]: formDataById,
      formIdentifier: props.dataId,
      content: isset(props, 'content', null),
      inputs: WebApps.Arrays.rekey(isset(props, 'inputs', {}), 'name'),
      footer: isset(props, 'footer', false),
      SET_STATE: props.SET_STATE,
    };
  }

  handleChange = (event) => {
    let formDataById = this.state[this.state.formIdentifier];
    formDataById[event.target.name] = event.target.value;

    this.state.SET_STATE({
      [this.state.dataId]: formDataById,
    });
  };

  render() {
    var { inputs, footer, content } = this.state;
    return (
      <React.Fragment>
        <form>
          {Object.keys(inputs).map((inputId) => {
            var input = inputs[inputId];
            if (isset(inputs[inputId], 'visible', true)) {
              if (isset(inputs[inputId], 'value', false)) {
                return (
                  <Input
                    key={inputId}
                    options={input}
                    handler={this.handleChange}
                    /* If the user wants to put in an actual value... */
                    value={isset(
                      this.state.formData,
                      inputs[inputId]['value'],
                      inputs[inputId]['value']
                    )}
                  />
                );
              } /* If the name field represents the key in the data... */ else if (
                isset(this.state.formData, inputs[inputId]['name'], false) &&
                this.state.formData[inputs[inputId]['name']] !== ''
              ) {
                return (
                  <Input
                    key={inputId}
                    options={input}
                    handler={this.handleChange}
                    value={this.state.formData[inputId]}
                  />
                );
              } /* Else we don't have data for this field yet... */ else {
                return (
                  <Input
                    key={inputId}
                    options={input}
                    handler={this.handleChange}
                  />
                );
              }
            }
          })}
        </form>
        {content ? <div>{content}</div> : null}
        {footer ? <FormFooter {...footer} /> : null}
      </React.Fragment>
    );
  }
}

export class FormFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sdkPath: isset(props, 'sdkPath', null),
      objectType: isset(props, 'objectType', null),
      objectKey: isset(props, 'objectKey', null),
      objectAction: isset(props, 'objectAction', null),
      submitBtn: props.submitBtn,
      deleteBtn: isset(props, 'deleteBtn', null),
      miscBtn: isset(props, 'miscBtn', null),
    };
  }

  render() {
    const {
      submitBtn,
      deleteBtn,
      miscBtn,
      sdkPath,
      objectType,
      objectKey,
      objectAction,
    } = this.state;

    const id = objectAction + '-' + objectType;
    const objectIdentifier = objectKey ? '_' + objectKey : '';
    const dataId = id.replace('-', '_') + objectIdentifier;

    return (
      <React.Fragment>
        <hr></hr>
        <div
          className='btn-toolbar justify-content-between mr-2'
          role='toolbar'
          aria-label='Toolbar with button groups'
        >
          {deleteBtn ? (
            <div className='btn-group'>
              <Button
                options={{
                  id: id,
                  type: 'delete',
                  sdkPath: sdkPath,
                  dataId: dataId,
                  redirect: isset(deleteBtn, 'redirect', null),
                  label: isset(deleteBtn, 'label', 'Delete'),
                  handler: isset(deleteBtn, 'handler', null),
                }}
              />
            </div>
          ) : null}
          {miscBtn ? (
            <div className='btn-group'>
              <Button
                options={{
                  id: id,
                  btnStyle: 'primary',
                  type: 'button',
                  sdkPath: sdkPath,
                  dataId: dataId,
                  redirect: isset(miscBtn, 'redirect', null),
                  label: isset(miscBtn, 'label', 'Click Here'),
                  handler: miscBtn.handler,
                  visible: miscBtn.visible,
                }}
              />
            </div>
          ) : null}
          <div className='btn-group'>
            <Button
              options={{
                id: id,
                btnStyle: 'primary',
                type: 'button',
                sdkPath: sdkPath,
                dataId: dataId,
                redirect: isset(submitBtn, 'redirect', null),
                label: isset(submitBtn, 'label', 'Update'),
                handler: submitBtn.handler,
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
