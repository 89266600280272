import React, { Component } from 'react';

/*
  If the route is /audit-log

  We will render audit log page and pass in the state from the users
  since that page will use most of the same information
*/

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PAGE_OPTIONS: props.PAGE_OPTIONS,
    };
  }

  componentDidMount() {
    this.state.PAGE_OPTIONS({
      pageInfo: { pageTitle: 'Users' },
    });
  }

  render() {
    return <div>You Are Viewing Some Cool Users Right Now</div>;
  }
}

export default Users;
