import React, { Component } from 'react';
import WebApps from '../assets/scripts/master';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const { isset } = WebApps.Mappings;

export default class Input extends Component {
  constructor(props) {
    super(props);

    const { options } = props;
    this.state = {
      label: isset(options, 'label', ''),
      isRequired: isset(options, 'required', true),
      isReadOnly: isset(options, 'readOnly', false),
      id: isset(options, 'id', WebApps.Strings.getRandomString(8)),
      name: isset(options, 'name', ''),
      size: isset(options, 'size', '6'),
      value: isset(props, 'value', ''),
      placeholder: isset(options, 'placeholder', ''),
      type: isset(options, 'type', 'text'),
      helpText: isset(options, 'helpText', null),
      options: isset(options, 'options', []),
      handler: props.handler,
    };
  }

  handleChange = (event) => {
    this.state.handler(event);
    this.setState({
      value: event.target.value,
    });
  };

  render() {
    const {
      label,
      isRequired,
      isReadOnly,
      id,
      size,
      name,
      value,
      placeholder,
      type,
      options,
      helpText,
    } = this.state;

    if (type === 'hidden') {
      return <input id={id} type='hidden' name={name} value={value} />;
    } else if (type === 'separator') {
      return <br />;
    } else if (type === 'hr') {
      return <hr />;
    } else {
      switch (type) {
        case 'textarea':
          return (
            <div className='form-group row'>
              <label
                className='col-md-4 control-label mt-2'
                style={{ textAlign: 'right' }}
              >
                {label}
                {isRequired ? (
                  <span className='required' style={{ color: 'red' }}>
                    {' '}
                    *{' '}
                  </span>
                ) : null}
              </label>
              <div className='col-sm-6'>
                <textarea
                  onChange={(e) => this.handleChange(e)}
                  placeholder={placeholder}
                  id={id}
                  name={name}
                  className='form-control'
                  rows='3'
                  value={value}
                  required={isRequired}
                  readOnly={isReadOnly}
                ></textarea>
                {helpText ? (
                  <span className='help-block' style={{ fontSize: 13 }}>
                    {helpText}
                  </span>
                ) : null}
              </div>
            </div>
          );
        case 'password':
          return (
            <div className='form-group row'>
              <label
                className='col-md-4 control-label mt-2'
                style={{ textAlign: 'right' }}
              >
                {label}
                {isRequired ? (
                  <span className='required' style={{ color: 'red' }}>
                    {' '}
                    *{' '}
                  </span>
                ) : null}
              </label>
              <div className='col-sm-6'>
                <input
                  onChange={(e) => this.handleChange(e)}
                  placeholder={placeholder}
                  required={isRequired}
                  id={id}
                  name={name}
                  type='password'
                  className='form-control'
                />
                {helpText ? (
                  <span className='help-block' style={{ fontSize: 13 }}>
                    {helpText}
                  </span>
                ) : null}
              </div>
            </div>
          );
        case 'date':
          return (
            <div className='form-group row'>
              <label
                className='col-md-4 control-label mt-2'
                style={{ textAlign: 'right' }}
              >
                {label}
                {isRequired ? (
                  <span className='required' style={{ color: 'red' }}>
                    {' '}
                    *{' '}
                  </span>
                ) : null}
              </label>
              <div className='col-sm-6'>
                <DatePicker
                  onChange={(e) => {
                    /**
                     * We need to add the event back to this variable as all that is returned is the date string
                     * We then need to format the string to either be a timestamp or a formatted date
                     */
                    this.handleChange({
                      target: { name: name, value: e },
                    });
                  }}
                  placeholderText={placeholder}
                  required={isRequired}
                  name={name}
                  id={id}
                  selected={value}
                  className='form-control'
                  dateFormat='yyyy-MM-dd'
                  highlightDates={[new Date()]}
                  required={isRequired}
                  readOnly={isReadOnly}
                />
                {helpText ? (
                  <span className='help-block' style={{ fontSize: 13 }}>
                    {helpText}
                  </span>
                ) : null}
              </div>
            </div>
          );

        case 'dropdown':
          return (
            <div className='form-group row'>
              <label
                className='col-md-4 control-label mt-2'
                style={{ textAlign: 'right' }}
              >
                {label}
                {isRequired ? (
                  <span className='required' style={{ color: 'red' }}>
                    {' '}
                    *{' '}
                  </span>
                ) : null}
              </label>
              <div className={'col-sm-' + size}>
                <select
                  id={id}
                  className='form-control input-sm'
                  tabIndex='1'
                  onChange={(e) => this.handleChange(e)}
                  name={name}
                  defaultValue={isset(options, 'default', null)}
                >
                  {isset(this.state.options, 'data', false)
                    ? this.state.options.data.map((option) => (
                        <option
                          key={option[options.key]}
                          value={option[options.key]}
                        >
                          {option[options.label]}
                        </option>
                      ))
                    : null}
                </select>
                {helpText ? (
                  <span className='help-block' style={{ fontSize: 13 }}>
                    {helpText}
                  </span>
                ) : null}
              </div>
            </div>
          );
        default:
          return (
            <div className='form-group row'>
              <label
                className='col-md-4 control-label mt-2'
                style={{ textAlign: 'right' }}
              >
                {label}
                {isRequired ? (
                  <span className='required' style={{ color: 'red' }}>
                    {' '}
                    *{' '}
                  </span>
                ) : null}
              </label>
              <div className={'col-sm-' + size}>
                <input
                  id={id}
                  type='text'
                  name={name}
                  className='form-control'
                  onChange={(e) => this.handleChange(e)}
                  placeholder={placeholder}
                  value={value}
                  required={isRequired}
                  readOnly={isReadOnly}
                />
                {helpText ? (
                  <span className='help-block' style={{ fontSize: 13 }}>
                    {helpText}
                  </span>
                ) : null}
              </div>
            </div>
          );
      }
    }
  }
}
