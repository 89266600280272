import React, { Component } from 'react';

class Roles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PAGE_OPTIONS: props.PAGE_OPTIONS,
    };
  }

  componentDidMount() {
    this.state.PAGE_OPTIONS({
      pageInfo: { pageTitle: 'Roles' },
    });
  }

  render() {
    return <div>You Are Viewing Some Cool Roles Right Now</div>;
  }
}

export default Roles;
