import React, { Component } from 'react';
import buildCard from '../../spa-master/components/cards';
import WebApps from '../../spa-master/assets/scripts/master';

const { isset } = WebApps.Mappings;
const { makeApiRequest } = WebApps.Requests;

class Client extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datasources: [],
      numberOfResources: 0,
    };

    this.fetchData();
  }

  fetchData = () => {
    makeApiRequest(window.apiClient.datasourcesGet)
      .then((data) => {
        /*
        var datasourceData = {
          numberOfDatasources: data.data.length,
          numberOfGroups: 0,
          numberOfTeams: 0,
          numberOfChannels: 0,
          numberOfTabs: 0,
        };

        data.data.forEach((datasource) => {
          ['Groups', 'Teams', 'Channels', 'Tabs'].forEach((resource) => {
            console.log(resource);
            datasourceData['numberOf' + resource] +=
              datasource[resource.toLowerCase()].length;
          });
        });
      */

        return isset(data, 'data', []).length > 0 ? data.data : [];
      })
      .then((datasources) => {
        makeApiRequest(window.apiClient.jobsGet)
          .then((data) => {
            var numberOfResources = 0;

            var jobs = isset(data, 'data', []).length > 0 ? data.data : [];
            for (var i = 0; i < datasources.length; i++) {
              numberOfResources += isset(jobs, i) ? jobs[i].number_of_tasks : 0;
            }

            this.setState({ datasources, numberOfResources });
          })
          .catch(function () {
            window.Alert('error', 'Unable to fetch data!');
          });
      })
      .catch(function (err) {
        console.log(err);
        window.Alert('error', 'Unable to fetch data!');
      });
  };

  render() {
    return buildCard(
      'dashboard',
      'Dashboard',
      <React.Fragment>
        <div className='simple--counter-container'>
          <div className='counter-container'>
            <div className='counter-content'>
              <h1 className='s-counter3 s-counter'>
                {this.state.datasources.length}
              </h1>
            </div>
            <p className='s-counter-text'>DATASOURCES</p>
          </div>
          <div className='counter-container'>
            <div className='counter-content'>
              <h1 className='s-counter3 s-counter'>
                {this.state.numberOfResources}
              </h1>
            </div>
            <p className='s-counter-text'>RESOURCES</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Client;
