import React, { Component } from 'react';
import buildCard from '../../../components/cards';
import Form from '../../../components/Form';
import Modal from '../../../components/Modal';
import { handleFormButtonAction } from '../../../components/_Utilities';
import queryString from 'query-string';

import WebApps from '../../../assets/scripts/master';

const { isset } = WebApps.Mappings;
const { makeApiRequest } = WebApps.Requests;

class Lock extends Component {
  
  constructor(props) {
    super(props);

    this.SET_GLOBAL_STATE = props.SET_GLOBAL_STATE;
    this.RE_INITIALIZE = props.RE_INITIALIZE;
    this.props = props;
    this.state = {
      IS_AUTHORIZED: props.IS_AUTHORIZED,
      PROCESSING: props.PROCESSING,
      ACCESS_CODE: isset(props, 'location') ? isset(queryString.parse(isset(props.location, 'search', {})), 'code') : null,
    };
  }

  componentDidMount() {
    
    this.RE_INITIALIZE();
    
    /**
     * If this is a redirect from an external Identity Provider,
     * we need to parse the url here and can log them in
     */
    if (this.state.ACCESS_CODE) {

      makeApiRequest(window.apiClient.authSignInPost, null, {
        code: this.state.ACCESS_CODE,
      })
        .then((data) => this.AUTH_HANDLER(data, false))
        .catch((err) => console.log('idp auth token err', err));
    } else {
      /**
       * Check if user is already authenticated and we can refresh their token,
       * if not, we will redirect them to login again
       */
      var refreshToken = window.cookies.get('CognitoIdentityServiceProvider.' + process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID + '.' + localStorage.getItem('username') + '.refreshToken'
      );

      /**
       * We will only enforce a refresh of the token if it is close to
       * being expired (< 12 minutes)
       */

      /*
      Lot's of issues trying to use this to not have to make the api call on every page
      refresh.
      var requireRefresh = (new Date(refreshToken + '.expires')) - new Date().getTime()) / 1000 / 60 < 12 ? true : false;
      */

      if ( refreshToken && localStorage.getItem('username') && !this.state.IS_AUTHORIZED ) {
        /**
         * Using cached credentials to try and auto authorize the user
         * if they still have the refresh token in their browser
         */
        makeApiRequest(window.apiClient.authTokenRefreshPost, null, {
          refresh_token: refreshToken,
          username: localStorage.getItem('idpUsername'),
          email: localStorage.getItem('email'),
        })
          .then((data) => this.AUTH_HANDLER(data, false))
          //.catch((err) => console.log('refresh token err', err));
      } else {
        console.log('Unable to refresh token, not found, log in manually');
        this.setState({
          PROCESSING: false,
        });
      }
    }
  }

  SET_STATE = (update) => {
    this.setState(update);
  };

  FORM_HANDLER = (e) => {
    handleFormButtonAction(e, this.state, null, this.AUTH_HANDLER, false, true);
  };

  AUTH_HANDLER = (e, alert = true) => {
    /**
     * If we received the data that we need from the cognito auth
     * then this was a successful sign in.
     */
    if (isset(e.data, 'data') && isset(e.data.data, 'app_user_id')) {
      var data = e.data.data;
      /**
       * Here we will rekey the user's attributes to store the respective values
       */
      var attributes = WebApps.Arrays.rekey(
        data.user_data.UserAttributes,
        'Name'
      );

      localStorage.setItem('username', attributes['sub']['Value']);
      localStorage.setItem('email', attributes['email']['Value']);

      WebApps.Client.setAuthCookies(
        process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        attributes['sub']['Value'],
        data.id_token,
        data.access_token,
        data.refresh_token
      );

      localStorage.setItem('idpUsername', data.user_data.Username);
      localStorage.setItem('apiKey', data.id_token);
      localStorage.setItem('userId', data.app_user_id);
      localStorage.setItem(
        'partnerId',
        attributes['custom:partnerId']['Value']
      );

      var clientIds = JSON.parse(attributes['custom:clientIds']['Value']);
      localStorage.setItem('clientId', clientIds.length > 0 ? clientIds[0] : 0);

      makeApiRequest(window.apiClient.usersUserIdGet, {
        userId: e.data.data.app_user_id,
      })
        .then((user) => {
          if (isset(user, 'data', '') === '') {
            this.signOutHandler('Failed to get userdata');
          } else {
            if (alert) window.Alert('success', 'Logged In');
            this.HANDLE_USER_SESSION(user);
          }
        })
        .catch((err) => this.signOutHandler(err));
    } else if (isset(e.data, 'CodeDeliveryDetails')) {
      if (e.data.CodeDeliveryDetails.DeliveryMedium === 'EMAIL') {
        /**
         * Here, a user has just signed up with the portal.
         * Let's let them know by either changing the text on the page or making a modal appear
         *
         * For now, we will just display a banner
         */
        window.Alert(
          'BLANK',
          'Thank you for signing up!\n Please check your email to verify your account, then you may proceed to Login.'
        );
      }
    } else {
      window.Alert('error', e.data.message);
      this.signOutHandler(e.data.message);
    }
  };

  AZURE_REDIRECTER = () => {
    /**
     * In order to make this SSO available for other SAML integrations, we need to pass the provider in dynamically
     * One way to accomplish this would be to run a crm check on the domain that they are trying to log into
     * Then using that domain, go find the appropriate identity provider, then come back here to continue with the redirect
     *
     * For Example
     *
     * makeApiRequest(window.apiClient.authProviderGet, { email : {{email from user input}} })
     */
    WebApps.URLs.redirect(
      process.env.REACT_APP_USER_POOL_DOMAIN +
        '/oauth2/authorize?response_type=code&client_id=' + process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID +
        '&redirect_uri=' + process.env.REACT_APP_DOMAIN +
        '&identity_provider=' + process.env.REACT_APP_IDENTITY_PROVIDER_AZURE
    );
  };

  signOutHandler = (message) => {
    console.log('Signed Out', message);
    this.SET_STATE({
      AUTHORIZING: false,
      IS_AUTHENTICATED: false,
      PROCESSING: false,
    });
    localStorage.clear();
    WebApps.Client.clearAuthCookies();
  };

  HANDLE_USER_SESSION = (user) => {
    var portalType =
      isset(this.state, 'PARTNER_ID', user.data.partner_id) === 0
        ? 'global'
        : isset(
            this.state,
            'CLIENT_ID',
            parseInt(localStorage.getItem('clientId'))
          ) === 0
        ? 'partner'
        : 'client';

    /**
     * Here we need to fetch the users latest state from dynamo
     * If they have one that isn't yet expired, we will update global
     * state using that information.
     *
     * What happens is that on page refresh, the users state resets to the defaults.
     * Certain things, we don't want to store in local storage as users could
     * manipulate the values.
     */
    makeApiRequest(window.apiClient.usersUserIdSessionGet, {
      userId: localStorage.getItem('username'),
    })
      .then((userState) => {
        if (Object.keys(isset(userState.data, 'state', {})).length > 0) {
          var stateUpdate = JSON.parse(userState.data.state);

          localStorage.setItem('partnerId', stateUpdate.PARTNER_ID);
          localStorage.setItem('clientId', stateUpdate.CLIENT_ID);

          stateUpdate.PORTAL_EMULATION_ACTIVE =
            parseInt(user.data.partner_id) > 0 ? false : true;

          portalType = stateUpdate.PORTAL_TYPE;
          return stateUpdate;
        } else {
          return {
            USER_ID: user.data.user_id,
            PORTAL_TYPE: portalType,
            PARTNER_ID: parseInt(user.data.partner_id),
            CLIENT_ID: parseInt(user.data.client_ids[0]),
            USER_INFO: {
              email: user.data.email,
              first_name: user.data.first_name,
              last_name: user.data.last_name,
            },
          };
        }
      })
      .then((stateUpdate) => {
        /**
         * Make a call to get application information for this user
         */
        makeApiRequest(
          window.apiClient.usersUserIdAccessGet,
          { userId: user.data.user_id },
          null,
          {
            queryParams: {
              portalType: portalType,
            },
          }
        ).then((data) => {
          WebApps.Sessions.updateUserSession(
            Object.assign({}, stateUpdate, {
              PORTAL_ACCESS: data.data,
              IS_AUTHORIZED: true,
              PROCESSING: false,
            })
          );
          this.SET_GLOBAL_STATE(
            Object.assign({}, stateUpdate, {
              PORTAL_ACCESS: data.data,
              IS_AUTHORIZED: true,
              PROCESSING: false,
            })
          );
        });
      });
  };

  render() {
    return this.state.PROCESSING ? (
      <center>
        <div style={{ paddingTop: 100 }}>
          {WebApps.Content.getSpinner(240, 240)}
        </div>
      </center>
    ) : this.state.IS_AUTHORIZED ? null : (
      <React.Fragment>
        <center>
          <div style={{ width: '40%', paddingTop: 60 }}>
            {buildCard(
              null,
              null,
              <div className='card-body'>
                <h3> WELCOME </h3>
                <ul className='nav nav-tabs' id='border-tabs' role='tablist'>
                  <li className='nav-item'>
                    <a
                      className='nav-link active'
                      id='border-home-tab'
                      data-toggle='tab'
                      href='#border-home'
                      role='tab'
                      aria-controls='border-home'
                      aria-selected='true'
                    >
                      Sign In
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      id='border-profile-tab'
                      data-toggle='tab'
                      href='#border-profile'
                      role='tab'
                      aria-controls='border-profile'
                      aria-selected='false'
                    >
                      Sign Up
                    </a>
                  </li>
                </ul>
                <div className='tab-content' id='border-tabsContent'>
                  <div
                    className='tab-pane fade show active'
                    id='border-home'
                    role='tabpanel'
                    aria-labelledby='border-home-tab'
                  >
                    <div className='mt-4'>
                      <Form
                        dataId={'create_auth_signIn'}
                        SET_STATE={this.SET_STATE}
                        inputs={[
                          {
                            label: 'Email',
                            name: 'email',
                            placeholder: 'johnDoe@email.com',
                          },
                          {
                            label: 'Password',
                            name: 'password',
                            type: 'password',
                          },
                        ]}
                        content={
                          <React.Fragment>
                            <a
                              href='#modal_create_forgot_password'
                              data-toggle='modal'
                            >
                              Don't Remember Your Password ?
                            </a>
                            {/*<br />
                            <a
                              href='#modal_create_resend_confirmation'
                              data-toggle='modal'
                            >
                              Didn't Receive Confirmation Link?
                            </a>*/}
                          </React.Fragment>
                        }
                        footer={{
                          sdkPath: 'authSignIn',
                          objectType: 'auth',
                          objectAction: 'create',
                          objectKey: 'signIn',
                          submitBtn: {
                            label: 'Sign In',
                            handler: this.FORM_HANDLER,
                          },
                          miscBtn: {
                            label: 'Sign In with SAML Azure SSO',
                            handler: this.AZURE_REDIRECTER,
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='border-profile'
                    role='tabpanel'
                    aria-labelledby='border-profile-tab'
                  >
                    <div className='mt-4'>
                      <Form
                        dataId={'create_auth_signUp'}
                        SET_STATE={this.SET_STATE}
                        inputs={[
                          {
                            label: 'Email',
                            name: 'email',
                            placeholder: 'johnDoe@email.com',
                          },
                          {
                            label: 'Password',
                            name: 'password',
                            type: 'password',
                            helpText: 'Password must exceed 15 characters',
                          },
                          {
                            label: 'Full Name',
                            name: 'full_name',
                            placeholder: 'John Doe',
                          },
                          {
                            label: 'Title / Position',
                            name: 'title',
                            placeholder: 'Account Manager',
                          },
                        ]}
                        footer={{
                          sdkPath: 'authSignUp',
                          objectType: 'auth',
                          objectAction: 'create',
                          objectKey: 'signUp',
                          submitBtn: {
                            label: 'Sign Up',
                            handler: this.FORM_HANDLER,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </center>
        <Modal
          title='Forgot Password'
          objectAction='create'
          objectType='forgot_password'
          body={{
            content: (
              <center>
                <p>Please enter the email that you signed up with.</p>
              </center>
            ),
            form: {
              SET_STATE: this.SET_STATE,
              inputs: [
                {
                  name: 'action',
                  type: 'hidden',
                  value: 'reset_request',
                },
                {
                  label: 'Email',
                  name: 'email',
                },
              ],
            },
          }}
          opts={{ size: 'lg' }}
          footer={{
            onSubmit: this.FORM_HANDLER,
            sdkPath: 'authForgotPassword',
            actionText: 'Submit',
            cancelText: 'Cancel',
            modalToShow: 'create_update_password',
          }}
        ></Modal>
        <Modal
          title='Update Password'
          objectAction='create'
          objectType='update_password'
          body={{
            form: {
              SET_STATE: this.SET_STATE,
              inputs: [
                {
                  name: 'action',
                  type: 'hidden',
                  value: 'confirm_request',
                },
                {
                  label: 'Email',
                  name: 'email',
                },
                {
                  label: 'Confirmation Code',
                  name: 'confirmation_code',
                },
                {
                  label: 'New Password',
                  name: 'new_password',
                  type: 'password',
                },
                {
                  label: 'Confirm New Password',
                  name: 'confirm_new_password',
                  type: 'password',
                },
              ],
            },
          }}
          opts={{ size: 'lg' }}
          footer={{
            onSubmit: this.FORM_HANDLER,
            sdkPath: 'authForgotPasswordConfirmation',
            actionText: 'Submit',
            cancelText: 'Cancel',
          }}
        />
        <Modal
          title='Resend Confirmation'
          objectAction='create'
          objectType='resend_confirmation'
          body={{
            content: (
              <center>
                <p>Please enter the email that you signed up with.</p>
              </center>
            ),
            form: {
              SET_STATE: this.SET_STATE,
              inputs: [
                {
                  label: 'Email',
                  name: 'email',
                },
              ],
            },
          }}
          opts={{ size: 'lg' }}
          footer={{
            onSubmit: this.FORM_HANDLER,
            sdkPath: 'authConfirmation',
            actionText: 'Submit',
            cancelText: 'Cancel',
          }}
        ></Modal>
      </React.Fragment>
    );
  }
}

export default Lock;
