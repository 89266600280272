import React, { Component } from 'react';
import WebApps from '../../spa-master/assets/scripts/master';
import buildCard from '../../spa-master/components/cards';
import Form from '../../spa-master/components/Form';
import { handleFormButtonAction } from '../../spa-master/components/_Utilities';

const { makeApiRequest } = WebApps.Requests;

class Configuration extends Component {
  constructor(props) {
    super(props);

    this.SET_GLOBAL_STATE = props.SET_GLOBAL_STATE;

    this.state = {
      configuration: {},
    };
  }

  componentDidMount() {
    this.SET_GLOBAL_STATE({
      PAGE_OPTIONS: {
        pageInfo: { pageTitle: 'Configuration' },
      },
    });

    this.fetchPageData();
  }

  SET_STATE = (update) => {
    this.setState(update);
  };

  FORM_HANDLER = (e) => {
    handleFormButtonAction(e, this.state, this.fetchPageData);
  };

  fetchPageData = () => {
    return makeApiRequest(window.apiClient.clientsClientIdGet, {
      clientId: localStorage.getItem('clientId'),
    })
      .then((data) => {
        this.setState({
          configuration: data.data,
        });
      })
      .catch(function () {
        window.Alert('error', 'Unable to fetch data!');
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className='row mt-3'>
          <div className='col-6'>
            {buildCard(
              'administrative-tools',
              'Configuration',
              <div className='card-body'>
                {Object.keys(this.state.configuration).length > 0 ? (
                  <React.Fragment>
                    <Form
                      dataId={'update_configuration_' + this.state.client_id}
                      SET_STATE={this.SET_STATE}
                      inputs={[
                        {
                          label: 'API KEY',
                          name: 'guid',
                          type: 'text',
                          readOnly: true,
                          required: false,
                          value: WebApps.Strings.maskText(
                            this.state.configuration.guid,
                            {
                              side: 'right',
                            }
                          ),
                          size: '7',
                        },
                      ]}
                    />
                  </React.Fragment>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Configuration;
