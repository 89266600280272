import BuildCardHeader from './content';
import WebApps from '../assets/scripts/master';
import React from 'react';

const { isset } = WebApps.Mappings;

export default function buildCard(icon, title, text, opts = {}) {
  var height = isset(opts, 'height', null);
  var scroll = isset(opts, 'scroll', false);
  var align = isset(opts, 'align', 'center');
  var titleAlign = isset(opts, 'titleAlign', false);
  var includeHeader = icon; // No icon, no header

  return (
    <React.Fragment>
      <div
        className={'card component-card_1 ' + (scroll ? 'overflow-auto' : '')}
        style={{ height: height }}
      >
        {includeHeader ? (
          titleAlign ? (
            <center>
              <BuildCardHeader
                icon={icon}
                title={title}
                subTitle={isset(opts, 'subtitle', '')}
              />
            </center>
          ) : (
            <BuildCardHeader
              icon={icon}
              title={title}
              subTitle={isset(opts, 'subtitle', '')}
            />
          )
        ) : null}
        <div className='card-body' style={{ textAlign: align }}>
          <div className='card-text'>{text}</div>
        </div>
      </div>
    </React.Fragment>
  );
}
