import React, { Component } from 'react';

import WebApps from '../assets/scripts/master';
import Form from './Form';
import Button from './Buttons';

const { isset } = WebApps.Mappings;
const { ucword } = WebApps.Strings;

export default class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: isset(props, 'title', null),
      objectAction: isset(props, 'objectAction', 'create'),
      objectType: isset(props, 'objectType', ''),
      objectKey: isset(props, 'objectKey', ''),
      show: isset(props, 'show', null),
      opts: props.opts,
      body: props.body,
      footer: props.footer,
    };
  }

  render() {
    const {
      opts,
      body,
      title,
      show,
      objectAction,
      objectType,
      objectKey,
      footer,
    } = this.state;

    const id = objectAction + '-' + objectType;
    const objectIdentifier = objectKey ? '_' + objectKey : '';

    return (
      <div
        className={'modal fade ' + (show ? 'show' : '')}
        id={'modal_' + objectAction + '_' + objectType + objectIdentifier}
        tabIndex='-1'
        role='dialog'
        style={{ display: show ? 'block' : 'none' }}
        aria-hidden='true'
      >
        <div className={'modal-dialog modal-' + isset(opts, 'size', 'md')}>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title'>
                {title
                  ? title
                  : ucword(objectAction) + ' ' + ucword(objectType)}
              </h4>
            </div>
            <div className='modal-body ' id={'modal-body-' + id}>
              {
                <ModalBody
                  id={id}
                  {...body}
                  dataId={id.replace('-', '_') + objectIdentifier}
                />
              }
            </div>
            {
              <ModalFooter
                {...footer}
                id={id}
                dataId={id.replace('-', '_') + objectIdentifier}
                objectAction={objectAction}
                objectType={objectType}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

export class ModalBody extends Modal {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      dataId: props.dataId,
      form: isset(props, 'form', null),
      content: isset(props, 'content', null),
    };
  }

  render() {
    return (
      <div className='modal-body' id={'modal-' + this.state.id}>
        {this.state.content}
        {isset(this.state, 'form', false) ? (
          <Form
            id={this.state.id}
            dataId={this.state.dataId}
            {...this.state.form}
          />
        ) : null}
      </div>
    );
  }
}

export class ModalFooter extends ModalBody {
  constructor(props) {
    super(props);

    this.id = props.id;
    this.dataId = props.dataId;

    this.state = {
      visible: isset(props, 'visible', true),
      onDelete: isset(props, 'onDelete', false),
      onSubmit: isset(props, 'onSubmit', false),
      actionText: isset(
        props,
        'actionText',
        ucword(props.objectAction) + ' ' + ucword(props.objectType)
      ),
      cancelText: isset(props, 'cancelText', 'Close'),
      sdkPath: isset(props, 'sdkPath', null),
      modalToShow: isset(props, 'modalToShow', null),
      tableToRefresh: isset(props, 'tableToRefresh', null),
    };
  }
  render() {
    const {
      onDelete,
      onSubmit,
      actionText,
      cancelText,
      sdkPath,
      visible,
      modalToShow,
      tableToRefresh,
    } = this.state;

    const deleteBtn = this.state.onDelete !== false;

    return (
      <div
        className={
          'modal-footer' + (deleteBtn ? ' justify-content-between' : '')
        }
      >
        {deleteBtn ? (
          <div className='btn-group'>
            <Button
              options={{
                id: this.id,
                dataId: this.dataId,
                type: 'delete',
                modal: true,
                label: 'Delete',
                handler: onDelete,
                sdkPath: sdkPath,
                modalToShow: modalToShow,
                tableToRefresh: tableToRefresh,
              }}
            />
          </div>
        ) : null}
        <div className='btn-group'>
          <Button
            options={{
              id: this.id,
              dataId: this.dataId,
              type: 'cancel',
              modal: true,
              label: cancelText,
            }}
          />
          <Button
            options={{
              id: this.id,
              dataId: this.dataId,
              btnStyle: 'primary',
              type: 'button',
              modal: true,
              label: actionText,
              handler: onSubmit,
              sdkPath: sdkPath,
              visible: visible,
              modalToShow: modalToShow,
              tableToRefresh: tableToRefresh,
            }}
          />
        </div>
      </div>
    );
  }
}
