import React, { Component } from 'react';
import buildCard from '../../../components/cards';
import WebApps from '../../../assets/scripts/master';

class Partners extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SET_GLOBAL_STATE: props.SET_GLOBAL_STATE,
      partners: [],
    };
  }

  componentDidMount() {
    this.state.SET_GLOBAL_STATE({
      PAGE_OPTIONS: {
        pageInfo: { pageTitle: 'Partners' },
        pageActions: [],
      },
    });

    WebApps.Requests.makeApiRequest(window.apiClient.partnersGet, {
      partnerId: localStorage.getItem('partnerId'),
    })
      .then((data) => {
        this.SET_STATE({
          partners: WebApps.Mappings.isset(data, 'data', []),
        });
        this.buildPartnerTable();
      })
      .catch((err) => {
        window.Alert('error', err);
      });
  }

  SET_STATE = (update) => {
    this.setState(update);
  };

  getPartners = (callback) => {
    let partners = WebApps.Mappings.isset(this.state, 'partners', []).map(
      (partner) => {
        return partner;
      }
    );

    callback(partners);
  };

  buildPartnerTable = () => {
    WebApps.Datatables.buildTable(
      'partner-table',
      {
        columns: [
          {
            source: 'name',
            title: 'Name',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'partner_id',
            title: 'Manage',
            align: 'center',
            render: function (data) {
              return data
                ? '<a href="/controller?action=enter_partner_portal&partner_id=' +
                    data +
                    '">Enter Partner Portal</a>'
                : '-';
            },
          },
        ],
        recordType: 'Partners',
      },
      this.getPartners
    );
  };

  render() {
    return (
      <div className='row mt-3'>
        <div className='col-12'>
          {buildCard(
            'handshake',
            'Partners',
            <div className='card-body'>
              <table id='partner-table'></table>
            </div>,
            { align: 'left' }
          )}
        </div>
      </div>
    );
  }
}

export default Partners;
