import React from 'react';
//import HEADER_ASSETS from './header_assets';
import MetaTags from 'react-meta-tags';
import WebApps from '../assets/scripts/master';
import { NavLink, Link } from 'react-router-dom';

// Components
import Button from '../components/Buttons';

const { isset } = WebApps.Mappings;
const { getIcon } = WebApps.Content;
const menuArrow = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='feather feather-chevron-right'
  >
    <polyline points='9 18 15 12 9 6'></polyline>
  </svg>
);

function setDefaultOptions(pageOptions, option) {
  switch (option) {
    case 'dropdown':
      return {
        enabled:
          isset(pageOptions, option, false) !== false
            ? isset(pageOptions[option], 'enabled', false)
            : pageOptions[option],
        options:
          isset(pageOptions, option, false) !== false
            ? isset(pageOptions[option], 'options', [])
            : pageOptions[option],
        key:
          isset(pageOptions, option, false) !== false
            ? isset(pageOptions[option], 'key', 'office_id')
            : pageOptions[option],
        size:
          isset(pageOptions, option, false) !== false
            ? isset(pageOptions[option], 'size', 'medium')
            : pageOptions[option],
        label:
          isset(pageOptions, option, false) !== false
            ? isset(pageOptions[option], 'label', null)
            : pageOptions[option],
        placeholder:
          isset(pageOptions, option, false) !== false
            ? isset(pageOptions[option], 'placeholder', 'Search All...')
            : pageOptions[option],
      };
    case 'daterangeSearch':
      return {
        enabled:
          isset(pageOptions, option, false) !== false
            ? isset(pageOptions[option], 'enabled', false)
            : pageOptions[option],
        options:
          isset(pageOptions, option, false) !== false
            ? isset(pageOptions[option], 'options', [
                'Today',
                'Yesterday',
                'Last 7 Days',
                'Last 30 Days',
                'This Month',
                'Last Month',
              ])
            : pageOptions[option],
        allowAggregation:
          isset(pageOptions, option, false) !== false
            ? isset(pageOptions[option], 'allowAggregation', true)
            : pageOptions[option],
        daterangeLabel:
          isset(pageOptions, option, false) !== false
            ? isset(pageOptions[option], 'daterangeLabel', null)
            : pageOptions[option],
        dropdownLabel:
          isset(pageOptions, option, false) !== false
            ? isset(pageOptions[option], 'dropdownLabel', 'office_id')
            : pageOptions[option],
        dropdownPlaceholder:
          isset(pageOptions, option, false) !== false
            ? isset(pageOptions[option], 'dropdownPlaceholder', null)
            : pageOptions[option],
        dropdownOptions:
          isset(pageOptions, option, false) !== false
            ? isset(pageOptions[option], 'dropdownOptions', [])
            : pageOptions[option],
      };
    case 'header':
      return {
        userActions:
          isset(pageOptions, option, false) !== false
            ? isset(pageOptions[option], 'userActions', [])
            : pageOptions[option],
      };
    default:
      break;
  }
}

export default function HEADER(props) {
  let globalState = isset(props, 'GLOBAL_STATE', {});

  let pageOptions = isset(globalState, 'PAGE_OPTIONS', {});

  pageOptions = {
    showFooter: isset(pageOptions, 'showFooter', true),
    pageTitle:
      isset(pageOptions, 'pageInfo', false) !== false
        ? pageOptions.pageInfo.pageTitle
        : 'Client Portal',
    pageInfo: isset(pageOptions, 'pageInfo', []),
    containContent: isset(pageOptions, 'containContent', true),
    showNavbar: isset(pageOptions, 'showNavbar', true),
    showToolbar: isset(pageOptions, 'showToolbar', false),
    showToolBarSearch: isset(pageOptions, 'showToolBarSearch', null),
    navbarOptions: isset(pageOptions, 'navbarOptions', []),
    showBreadcrumb: isset(pageOptions, 'showBreadcrumb', true),
    showSearchbar: isset(pageOptions, 'showSearchbar', false),
    showSidebar: isset(pageOptions, 'showSidebar', true),
    pageActions: isset(pageOptions, 'pageActions', []),
    dropdown: setDefaultOptions(pageOptions, 'dropdown'),
    secondaryDropdown: setDefaultOptions(pageOptions, 'dropdown'),
    daterangeSearch: setDefaultOptions(pageOptions, 'daterangeSearch'),
    header: setDefaultOptions(pageOptions, 'header'),
  };

  globalState.PAGE_OPTIONS = pageOptions;

  return (
    <React.Fragment>
      {METATAGS(pageOptions)}
      {/*
        Due to what I can only assume is JS being aync, the assets when loaded
        through a render, do not load in order which will cause the app to not
        be initialized, limiting the functionality on the page.
        These assets, are defined in the index.html page.

        HEADER_ASSETS()
      */}
      {/*<!--  BEGIN NAVBAR  -->*/}
      {pageOptions.showNavbar ? NAVBAR(globalState) : null}
      {/*<!--  END NAVBAR  -->*/}
      {/*<!--  BEGIN MAIN CONTAINER  -->*/}
      {/**
       * The main-container class will offset everything to the right to account
       * for the sidebar, so we we will allow for things to be outside of the container
       */}
      {pageOptions.containContent ? (
        <div className='main-container' id='container'>
          {/*<!--  BEGIN SIDEBAR  -->*/}
          {pageOptions.showSidebar ? SIDEBAR(globalState) : null}
          {/*<!--  END SIDEBAR  -->*/}
          <div id='content' className='main-content'>
            {/*<!--  BEGIN TOOLBAR  -->*/}
            {TOOLBAR(pageOptions)}
            {/*<!--  END TOOLBAR  -->*/}
            <div className='layout-px-spacing'>{props.children}</div>
            {FOOTER(props)}
          </div>
          {/*<!--  END MAIN CONTAINER  -->*/}
        </div>
      ) : (
        <React.Fragment>
          {pageOptions.showToolbar ? TOOLBAR(pageOptions) : null}
          <div className='layout-px-spacing'>{props.children}</div>{' '}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

function TOOLBAR(pageOptions) {
  return pageOptions.showBreadcrumb ? (
    <div className='sub-header-container mb-2'>
      <div className='header navbar navbar-expand'>
        <ul className='navbar-nav flex-row mt-1' style={{ height: 50 }}>
          <li className='ml-2'>
            <div className='breadcrumb-one' aria-label='breadcrumb'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to='/'>
                    <img
                      alt='broken icon'
                      src={getIcon('home', 10, true)}
                      className='mr-2 mb-1'
                    />
                  </Link>
                </li>
                {window.location.href
                  .split('/')
                  .slice(3)
                  .map((urlPart) => {
                    if (!Number.isInteger(+urlPart)) {
                      return (
                        <li
                          className='breadcrumb-item'
                          style={{ fontSize: 18 }}
                        >
                          {' '}
                          <Link to={'/' + urlPart} className='breadcrumb-link'>
                            {WebApps.Strings.ucword(urlPart)}
                          </Link>
                        </li>
                      );
                    }
                  })}
              </ol>
            </div>
          </li>
        </ul>
        <ul className='navbar-nav flex-row mt-1 ml-auto' style={{ height: 50 }}>
          {pageOptions.daterangeSearch.enabled ||
          pageOptions.dropdown.enabled ? (
            [pageOptions.dropdown, pageOptions.secondaryDropdown].map(
              (dropdown) => (
                <React.Fragment>
                  <li className='nav-item more-dropdown mt-1'>
                    <a
                      className='dropdown-toggle btn'
                      href='#'
                      role='button'
                      id='customDropdown'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      <span>{dropdown.label}</span>{' '}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='feather feather-chevron-down'
                      >
                        <polyline points='6 9 12 15 18 9'></polyline>
                      </svg>
                    </a>
                    <div
                      className='dropdown-menu dropdown-menu-right'
                      aria-labelledby='customDropdown'
                    >
                      {dropdown.options.map((option) => (
                        <a
                          key={option.label}
                          className='dropdown-item ml-2'
                          dataValue={option.label}
                          href='#'
                        >
                          {option.label}
                        </a>
                      ))}
                    </div>
                  </li>
                  <li className='nav-item m-2'></li>
                </React.Fragment>
              )
            )
          ) : pageOptions.pageActions ? (
            <React.Fragment>
              {isset(pageOptions, 'showToolBarSearch') ? (
                <li
                  className='nav-item align-self-center mr-2'
                  style={{ width: 750, height: 50 }}
                >
                  <div className='search-bar'>
                    <input
                      id='toolbar-search-input'
                      onChange={pageOptions.showToolBarSearch.handler}
                      type='text'
                      className='form-control search-form-control'
                      placeholder={isset(
                        pageOptions.showToolBarSearch,
                        'placeholder',
                        'Search...'
                      )}
                    />
                  </div>
                </li>
              ) : null}
              <li className='nav-item mt-1'>
                <div className='btn-group pull-right'>
                  {pageOptions.pageActions.map((action) => {
                    if (isset(action, 'type', '') === 'separator') {
                      return (
                        <span className='mr-2' style={{ fontSize: 25 }}>
                          |
                        </span>
                      );
                    }
                    if (isset(action, 'visible', true)) {
                      if (isset(action, 'modal', false)) {
                        action.href =
                          '#modal_' +
                          action.modal +
                          (isset(action, 'id', false) === false
                            ? ''
                            : '-' + action.id);
                      }
                      if (isset(action, 'enabled', true)) {
                        action.enabled = true;
                      }
                      return (
                        <a
                          key={action.name}
                          {...(isset(action, 'newTab', false) === false
                            ? ''
                            : 'target="_blank"')}
                          href={action.href}
                          onClick={isset(action, 'onClick', null)}
                          className={
                            'btn btn-sm mr-2 btn-outline-' +
                            isset(action, 'btnClass', 'dark')
                          }
                          data-toggle='modal'
                        >
                          {action.name}
                        </a>
                      );
                    }
                  })}
                </div>
              </li>
            </React.Fragment>
          ) : null}
        </ul>
      </div>
    </div>
  ) : null;
}

function SIDEBAR(props) {
  let PORTAL_ACCESS = isset(props, 'PORTAL_ACCESS', []);
  let HIDE_RENDER = isset(props, 'PROCESSING', true);

  const globalPages = [
    {
      function_group_id: 0,
      icon: 'dashboard',
      is_active: 1,
      name: 'Dashboard',
      uri: 'dashboard',
    },
  ];

  return (
    /*<!--  BEGIN SIDEBAR  -->*/
    <div className='sidebar-wrapper sidebar-theme'>
      <nav id='sidebar' className=''>
        <ul className='navbar-nav theme-brand flex-row text-center'>
          {/*<li className='nav-item theme-logo'>
            <a href='/'>
              
            <img alt="broken icon" 
                src={process.env.REACT_APP_THEMES + '/assets/img/logo.svg'}
                className='navbar-logo'
                alt='logo'
              />
            </a>
          </li>*/}
          <li
            className='nav-item theme-text ml-5'
            style={{ paddingTop: 10, letterSpacing: 5 }}
          >
            <NavLink to='/' className=''>
              {PORTAL_ACCESS.length > 0
                ? PORTAL_ACCESS[0].app_abbreviation.toUpperCase()
                : ''}
            </NavLink>
          </li>
        </ul>
        <div className='shadow-bottom'></div>
        <ul
          className='list-unstyled menu-categories ps ps--active-y'
          id='accordionExample'
        >
          <li className='menu mb-5' key={'spacer'}></li>
          {globalPages.map((page) => (
            <li className='menu' key={page.name}>
              <NavLink
                to={'/'}
                aria-expanded='true'
                className='dropdown-toggle'
              >
                <div className='' style={{ fontSize: 17 }}>
                  <img
                    alt='broken icon'
                    src={getIcon(page.icon, 14, true)}
                    className='mr-2 mb-1'
                  />
                  {page.name}
                </div>
              </NavLink>
            </li>
          ))}

          {HIDE_RENDER
            ? null
            : PORTAL_ACCESS.map((group) => {
                /**
                 * Remove any items from the group that are not shown
                 * as that will add a dropdown where we don't need one
                 */
                group.functions = group.functions.filter(
                  (group_function) => group_function.show_in_nav
                );

                if (
                  group.functions.length === 1 &&
                  group.functions[0].is_group_master
                ) {
                  return (
                    <li className='menu' key={group.group_name}>
                      <NavLink
                        to={'/' + group.group_uri}
                        aria-expanded='true'
                        className='dropdown-toggle'
                      >
                        <div className='' style={{ fontSize: 17 }}>
                          <img
                            alt='broken icon'
                            src={getIcon(group.group_icon, 14, true)}
                            className='mr-2 mb-1'
                          />

                          {group.group_name}
                        </div>
                      </NavLink>
                    </li>
                  );
                } else if (group.functions.length > 0) {
                  return (
                    <li className='menu' key={group.group_name}>
                      <span
                        href={
                          '#' + group.group_name.replace(' ', '').toLowerCase()
                        }
                        data-toggle='collapse'
                        aria-expanded='false'
                        className='dropdown-toggle'
                      >
                        <div className='' style={{ fontSize: 17 }}>
                          <img
                            alt='broken icon'
                            src={getIcon(group.group_icon, 14, true)}
                            className='mr-2 mb-1'
                          />
                          <span>{group.group_name}</span>
                        </div>
                        <div>{menuArrow}</div>
                      </span>
                      <ul
                        className='collapse submenu list-unstyled'
                        id={group.group_name.replace(' ', '').toLowerCase()}
                        data-parent='#accordionExample'
                      >
                        {group.functions.map((functions) => {
                          if (functions.show_in_nav === 1) {
                            return (
                              <li key={functions.name}>
                                <NavLink
                                  to={
                                    '/' +
                                    group.group_uri +
                                    '/' +
                                    isset(functions, 'uri', '')
                                  }
                                >
                                  {functions.abridged_name}
                                </NavLink>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </li>
                  );
                } else return null;
              })}
        </ul>
      </nav>
    </div>
    /*<!--  END SIDEBAR  -->*/
  );
}

function NAVBAR(globalState) {
  /* BEGIN NAVBAR */
  return (
    <div
      className={
        globalState.PAGE_OPTIONS.showSidebar ? 'header-container fixed-top' : ''
      }
    >
      <div
        className='header navbar navbar-expand-sm'
        style={{ backgroundColor: '#27262e', height: 57 }}
      >
        <div className='page-logo ml-3 mt-2 mr-2'>
          <h1>
            {globalState.PORTAL_ACCESS.length > 0
              ? globalState.PORTAL_ACCESS[0].app_abbreviation.toUpperCase()
              : ''}
          </h1>
        </div>
        {globalState.PAGE_OPTIONS.navbarOptions.map((link) => (
          <ul className='navbar-item flex-row navbar-dropdown ml-2 mr-2'>
            <li>
              {isset(link, 'to') ? (
                <a href={link.to}>{link.label}</a>
              ) : (
                <Button
                  options={{
                    btnStyle: 'primary',
                    label: link.label,
                  }}
                />
              )}
            </li>
          </ul>
        ))}
        <ul className='navbar-item flex-row search-ul ml-3'>
          {globalState.PAGE_OPTIONS.showSearchbar ? (
            <li className='nav-item align-self-center' style={{ width: 750 }}>
              <div className='search-bar'>
                <input
                  type='text'
                  className='form-control search-form-control'
                  placeholder='Search...'
                />
              </div>
            </li>
          ) : null}
        </ul>
        <ul className='navbar-item flex-row navbar-dropdown'>
          {globalState.PORTAL_EMULATION_ACTIVE ? (
            <li>
              <Button
                options={{
                  btnStyle: 'danger',
                  type: 'control',
                  value: 'exit_' + globalState.PORTAL_TYPE + '_portal',
                  label:
                    'Exit ' +
                    (globalState.CLIENT_ID > 0 ? 'Client' : 'Partner') +
                    ' Portal',
                }}
              />
            </li>
          ) : null}
          {/*<li className='nav-item dropdown notification-dropdown'>
            <a
              href='javascript:void(0);'
              className='nav-link dropdown-toggle'
              id='notificationDropdown'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='true'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                className='feather feather-bell'
              >
                <path d='M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9'></path>
                <path d='M13.73 21a2 2 0 0 1-3.46 0'></path>
              </svg>
              <span className='badge badge-primary'></span>
            </a>
            <div
              className='dropdown-menu position-absolute'
              aria-labelledby='notificationDropdown'
            >
              <div className='notification-scroll'>
                {db.notifications.map((notification) => (
                  <div className='dropdown-item'>
                    <div className='media server-log'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='feather feather-check'
                      >
                        <polyline points='20 6 9 17 4 12'></polyline>
                      </svg>
                      <div className='media-body'>
                        <div className='data-info mt-1'>
                          <h6 className=''>{notification.title}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </li>
          */}
          <li className='nav-item dropdown user-profile-dropdown'>
            <a
              href='javascript:;'
              className='dropdown-toggle'
              data-toggle='dropdown'
              aria-expanded='false'
            >
              <img
                alt='broken icon'
                src={getIcon('user', 14, true)}
                className='mr-2 mb-1'
              />
              <span
                style={{
                  color: '#e0e6ed',
                  fontSize: 16,
                }}
              >
                {globalState.USER_INFO.first_name +
                  ' ' +
                  globalState.USER_INFO.last_name}
              </span>
            </a>
            <div
              className='dropdown-menu dropdown-menu-right ml-5'
              style={{ border: '1px solid grey' }}
            >
              <Link
                to='/controller?action=log_out'
                className='dropdown-item'
                style={{ fontSize: 14 }}
              >
                <img
                  alt='broken icon'
                  src={getIcon('exit', 14, true)}
                  className='mr-2 mb-1'
                />
                Sign Out
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
  /* END NAVBAR */
}

/**
 * Adding this footer adds extra scrolling to the page
 */
function FOOTER(props) {
  return (
    <div className='footer-wrapper'>
      {
        <div className='footer-section f-section-1'>
          <p className=''>
            {
              //new Date().toISOString().slice(0, 10)
            }
          </p>
        </div>
      }
      <div className='footer-section f-section-2'>
        <p className=''>Coded with React</p>
      </div>
    </div>
  );
}

/**
 * Using this metatags function and subsequent component,
 * lets us update the title of the page dynamically
 */
function METATAGS(props) {
  return (
    <MetaTags>
      <title>{props.pageTitle}</title>
    </MetaTags>
  );
}
