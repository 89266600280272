import WebApps from '../../spa-master/assets/scripts/master';

import $ from 'jquery';

const { isset } = WebApps.Mappings;
const { makeApiRequest } = WebApps.Requests;

/**
 * To utilize this function you must create a FORM_HANDLER in the parent
 * file and simply call this function from the handler, passing in the required
 * parameters
 *
 * @e the event that triggered your handler
 * @state the current state of the parent for the formdata
 * @postActionFetch the function to retrieve the updated data
 * @callback a callback if required
 */
export const handleFormButtonAction = function (
  e,
  state,
  postActionFetch = null,
  callback = null,
  alert = true,
  validate = null
) {
  /* Parse out the action that needs to be taken */
  var apiAction = e.target.id.split('-')[0]; // create
  var objectType = e.target.id.split('-')[1]; // user
  var stopLoader = isset(e, 'stopLoader', null);

  if (isset(e.target, 'dataset', false)) {
    var formIdentifier = isset(e.target.dataset, 'id', null);
    var sdkPath = isset(e.target.dataset, 'sdk', null);
    var redirect = isset(e.target.dataset, 'redirect', null);
    var tableToRefresh = isset(e.target.dataset, 'tabletorefresh', null);
    var modalToShow = isset(e.target.dataset, 'modaltoshow', null);
    var formData = isset(state, formIdentifier, {});
  }

  var postData = {};
  Object.entries(formData).forEach(
    (mapping) => (postData[mapping[0].replace('-', '_')] = mapping[1])
  );

  if (Object.keys(postData).length === 0) {
    window.Alert('error', 'There is no data to send');
    return false;
  }

  var params = {};
  var body = {};
  var addParams = {};

  /*
   * We need to parse out all the necessary identifiers by converting any ById resources in the
   * endpoint, to be variables added to the params dict.
   *
   * Basically, we split resourcesResourceId by capital letters then find all the 'Id's
   * For each id, get the previous element of the list and use that as the required param.
   */
  var listOfResources = sdkPath.split(/(?=[A-Z])/);

  listOfResources.map((resource, idx) => {
    if (resource === 'Id') {
      let previousResource = listOfResources[idx - 1].toLowerCase();
      params[previousResource + 'Id'] =
        state[formIdentifier][previousResource + '_id'];
    }
  });

  var notificationVerb = '';

  switch (apiAction) {
    case 'create':
      sdkPath += 'Post';
      notificationVerb = 'Created';
      body = postData;
      break;
    case 'delete':
      sdkPath += 'Delete';
      notificationVerb = 'Deleted';

      body = postData;
      break;
    case 'edit':
    case 'update':
      sdkPath += 'Patch';
      notificationVerb = 'Updated';

      body = postData;
      break;
    default:
      sdkPath += 'Get';

      body = postData;
      break;
  }

  /*
    Here we can add form validation
  */
  var validRequest = true;
  var errorMessage = 'Unknown Error';

  if (validate) {
    Object.keys(postData).forEach((key) => {
      if (!isset(postData, key)) {
        errorMessage = 'Please fill in the required fields';
        validRequest = false;
      }
    });
  }

  if ((validate && validRequest) || !validate) {
    makeApiRequest(window.apiClient[sdkPath], params, body, addParams)
      .then((e) => (callback ? callback(e) : null))
      .then(() =>
        alert
          ? window.Alert(
              'success',
              WebApps.Strings.ucword(objectType) + ' ' + notificationVerb
            )
          : null
      )
      // If requested, redirect user to configured url
      .then(() => (redirect ? (window.location.href = '/' + redirect) : null))
      // Disable the loader in the button that initiated this call
      .then(stopLoader ? stopLoader() : null)
      .then(() =>
        postActionFetch
          ? postActionFetch().then(() =>
              tableToRefresh
                ? $('#' + tableToRefresh + '-table')
                    .DataTable()
                    .ajax.reload()
                : null
            )
          : tableToRefresh
          ? $('#' + tableToRefresh + '-table')
              .DataTable()
              .ajax.reload()
          : null
      )
      .then(
        modalToShow ? window.$('#modal_' + modalToShow).modal('show') : null
      )
      /* Here we can add logic to clear the form since there will be no refresh */
      .catch((e) => {
        console.log(e);
        window.Alert('error', 'Failed to ' + objectType + ' ' + apiAction);
      });
  } else {
    window.Alert('error', errorMessage);
    stopLoader();
  }
};

/**
 * TODO: Function interval runs way more than the declared time
 * @param {*} fetchFunction
 * @param {*} frequencyInMinutes
 * @param {*} tableToRefresh
 */
export const invokeIntervalRefresh = function (
  fetchFunction,
  frequencyInMinutes,
  tableToRefresh
) {
  setInterval(
    () =>
      fetchFunction().then(
        $('#' + tableToRefresh + '-table')
          .DataTable()
          .ajax.reload()
      ),
    5000
  );
};
