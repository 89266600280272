import React, { Component } from 'react';
import buildCard from '../../spa-master/components/cards';
import WebApps from '../../spa-master/assets/scripts/master';

const { makeApiRequest } = WebApps.Requests;

class Clients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SET_GLOBAL_STATE: props.SET_GLOBAL_STATE,
      clients: [],
    };
  }

  componentDidMount() {
    this.state.SET_GLOBAL_STATE({
      PAGE_OPTIONS: {
        pageInfo: { pageTitle: 'Clients' },
        pageActions: [],
      },
    });

    makeApiRequest(window.apiClient.clientsGet)
      .then((data) => {
        this.SET_STATE({
          clients: data.data,
        });
        this.buildClientTable();
      })
      .catch(function () {
        window.Alert('error', 'Unable to fetch data!');
      });
  }

  SET_STATE = (update) => {
    this.setState(update);
  };

  getClients = (callback) => {
    let clients = WebApps.Mappings.isset(this.state, 'clients', []).map(
      (client) => {
        return client;
      }
    );

    callback(clients);
  };

  buildClientTable = () => {
    WebApps.Datatables.buildTable(
      'client-table',
      {
        columns: [
          {
            source: 'name',
            title: 'Name',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'client_id',
            title: 'Manage',
            align: 'center',
            render: function (data) {
              return data
                ? '<a href="/controller?action=enter_client_portal&client_id=' +
                    data +
                    '">Enter Client Portal</a>'
                : '-';
            },
          },
        ],
        recordType: 'Clients',
      },
      this.getClients
    );
  };

  render() {
    return (
      <div className='row mt-3'>
        <div className='col-12'>
          {buildCard(
            'handshake',
            'Clients',
            this.state.clients.length > 0 ? (
              <React.Fragment>
                <div className='card-body'>
                  <table id='client-table'></table>
                </div>
              </React.Fragment>
            ) : null,
            { align: 'left' }
          )}
        </div>
      </div>
    );
  }
}

export default Clients;
