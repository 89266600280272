import WebApps from '../assets/scripts/master';

export function showError(code, text, redirect) {
  window.Snackbar.show({
    text: 'Failed to Retrieve ' + text,
    pos: 'top-center',
    actionTextColor: '#fff',
    backgroundColor: '#e7515a',
    actionText: 'X',
  });

  return redirect ? WebApps.URLs.redirect(redirect) : false;
}
export function showSuccess(code, text, redirect) {
  window.Snackbar.show({
    text: 'Success! ' + text + ' Successfully!',
    pos: 'top-center',
    actionTextColor: '#fff',
    backgroundColor: '#1abc9c',
    actionText: 'X',
  });

  return redirect ? WebApps.URLs.redirect(redirect) : false;
}

export default function alert(type, text, redirect) {
  switch (type) {
    case 'BLANK':
      window.Snackbar.show({
        text: text,
        pos: 'top-center',
        actionTextColor: '#fff',
        backgroundColor: '#1abc9c',
        actionText: 'X',
      });
      break;
    case 'success':
      window.Snackbar.show({
        text: 'Success! ' + text + ' Successfully!',
        pos: 'top-center',
        actionTextColor: '#fff',
        backgroundColor: '#1abc9c',
        actionText: 'X',
      });
      break;
    case 'error':
      window.Snackbar.show({
        text: 'Error: ' + text,
        pos: 'top-center',
        actionTextColor: '#fff',
        backgroundColor: '#e7515a',
        actionText: 'X',
      });
      break;
    case 'MISSING_RESOURCE':
      window.Snackbar.show({
        text: 'Failed to Retrieve ' + text,
        pos: 'top-center',
        actionTextColor: '#fff',
        backgroundColor: '#e7515a',
        actionText: 'X',
      });

      return redirect ? WebApps.URLs.redirect(redirect) : false;
    case 'warning':
      window.Snackbar.show({
        text: 'Warning: ' + text,
        pos: 'top-center',
        actionTextColor: '#fff',
        backgroundColor: '#e2a03f',
        actionText: 'X',
      });
      break;
    default:
      window.Snackbar.show({
        text: text,
        pos: 'top-center',
        actionTextColor: '#fff',
        backgroundColor: '#1abc9c',
        actionText: 'X',
      });
      break;
  }
}
