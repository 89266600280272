import React, { Component } from 'react';
import buildCard from '../../spa-master/components/cards';
import WebApps from '../../spa-master/assets/scripts/master';

const { makeApiRequest } = WebApps.Requests;
const { refreshTable } = WebApps.Datatables;

class Jobs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      RE_INITIALIZE: props.RE_INITIALIZE,
      SET_GLOBAL_STATE: props.SET_GLOBAL_STATE,
    };
  }

  componentDidMount() {
    this.state.RE_INITIALIZE();
    this.state.SET_GLOBAL_STATE({
      PAGE_OPTIONS: {
        pageInfo: { pageTitle: 'Jobs' },
      },
    });

    this.buildJobTable();
  }

  getJobs = (callback) => {
    makeApiRequest(window.apiClient.jobsGet)
      .then((data) => {
        callback(
          WebApps.Mappings.isset(data, 'data', []).length > 0 ? data.data : []
        );
      })
      .catch(function () {
        window.Alert('error', 'Unable to fetch data!');
      });
  };

  buildJobTable = () => {
    WebApps.Datatables.buildTable(
      'job-table',
      {
        columns: [
          {
            source: 'id',
            title: 'Job #',
            align: 'center',
            render: function (data) {
              return data;
            },
          },
          {
            source: 'datasource',
            title: 'Datasource',
            align: 'center',
            render: function (data) {
              return data ? data.description : '-';
            },
          },
          {
            source: 'start_time',
            title: 'Started At',
            align: 'center',
            render: function (data) {
              return data ? WebApps.Time.relativeToNow(data) : '-';
            },
          },
          {
            source: 'number_of_tasks',
            title: 'Number Of Tasks',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'id',
            title: 'View Details',
            align: 'center',
            render: function (data) {
              return data
                ? '<a href="/jobs/' +
                    data +
                    '">' +
                    WebApps.Content.getIcon('search', 18) +
                    '</a>'
                : '-';
            },
          },
        ],
        order: [[0, 'desc']],
        recordType: 'Jobs',
      },
      this.getJobs
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className='row mt-3'>
          <div className='col-12'>
            {buildCard(
              'job--v1',
              'Jobs',
              <div className='card-body'>
                <table id='job-table'></table>
              </div>,
              { align: 'left' }
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Jobs;
