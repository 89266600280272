import React, { Component } from 'react';
import buildCard from '../../../../components/cards';
import Modal from '../../../../components/Modal';
import { handleFormButtonAction } from '../../../../components/_Utilities';

class Functions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SET_GLOBAL_STATE: props.SET_GLOBAL_STATE,
      functions: [],
    };
  }

  componentDidMount() {
    this.state.SET_GLOBAL_STATE({
      PAGE_OPTIONS: {
        pageInfo: { pageTitle: 'Functions' },
        pageActions: [
          {
            name: 'Create Function',
            modal: 'create_function',
            btnClass: 'primary',
          },
        ],
      },
    });

    //this.fetchDatasources().then(() => this.buildDatasourceTable());
  }

  SET_STATE = (update) => {
    this.setState(update);
  };

  FORM_HANDLER = (e) => {
    handleFormButtonAction(e, this.state, this.fetchDatasources);
  };

  render() {
    return (
      <React.Fragment>
        <div className='row mt-3'>
          <div className='col-12'>
            {buildCard(
              'new-resume-template',
              'Function Groups',
              <div className='card-body'>
                <table id='function-group-table'></table>
              </div>,
              { align: 'left' }
            )}
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-12'>
            {buildCard(
              'new-resume-template',
              'Functions',
              <div className='card-body'>
                <table id='function-table'></table>
              </div>,
              { align: 'left' }
            )}
          </div>
        </div>
        <Modal
          objectAction='create'
          objectType='function'
          body={{
            form: {
              SET_STATE: this.SET_STATE,
              inputs: [
                {
                  label: 'Uri',
                  name: 'uri',
                },
                {
                  label: 'Abridged Name',
                  name: 'abridged_name',
                },
                {
                  label: 'Icon',
                  name: 'icon',
                },
                {
                  label: 'Name',
                  name: 'name',
                },
                {
                  label: 'Function Id',
                  name: 'function_id',
                },
                {
                  label: 'Function Group Id',
                  name: 'function_group_id',
                },
                {
                  label: 'Is Active',
                  name: 'is_active',
                },
                {
                  label: 'Is Group Master',
                  name: 'is_group_master',
                },
                {
                  label: 'Show In Nav',
                  name: 'show_in_nav',
                },
                {
                  label: 'Used In Client',
                  name: 'used_in_client',
                },
                {
                  label: 'Used In Global',
                  name: 'used_in_global',
                },
                {
                  label: 'Used In Partner',
                  name: 'used_in_partner',
                },
              ],
            },
          }}
          opts={{ size: 'md' }}
          footer={{
            onSubmit: this.FORM_HANDLER,
            //tableToRefresh: 'function',
            sdkPath: 'developmentFunctions',
          }}
        ></Modal>
      </React.Fragment>
    );
  }
}

export default Functions;
