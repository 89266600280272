import React, { Component } from 'react';
import buildCard from '../../spa-master/components/cards';

import Partner from './Partner';
import Client from './Client';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.PORTAL_TYPE = props.PORTAL_TYPE;

    this.state = {
      SET_GLOBAL_STATE: props.SET_GLOBAL_STATE,
    };
  }

  componentDidMount() {
    this.state.SET_GLOBAL_STATE({
      PAGE_OPTIONS: {
        pageInfo: {
          pageTitle: 'Dashboard',
        },
        showBreadcrumb: false,
      },
    });
  }

  render() {
    return (
      <div className='row mt-2'>
        <div className='col-12'>
          {this.PORTAL_TYPE === 'global' ? (
            buildCard(
              'dashboard',
              'Dashboard',
              <React.Fragment>
                <div className='simple--counter-container'>
                  <div className='counter-container'>
                    <div className='counter-content'>
                      <h1 className='s-counter2 s-counter'>710</h1>
                    </div>
                    <p className='s-counter-text'>HOURS</p>
                  </div>

                  <div className='counter-container'>
                    <div className='counter-content'>
                      <h1 className='s-counter3 s-counter'>915</h1>
                    </div>
                    <p className='s-counter-text'>TICKETS</p>
                  </div>

                  <div className='counter-container'>
                    <div className='counter-content'>
                      <h1 className='s-counter4 s-counter'>580</h1>
                    </div>
                    <p className='s-counter-text'>CUSTOMERS</p>
                  </div>
                </div>
              </React.Fragment>
            )
          ) : this.PORTAL_TYPE === 'partner' ? (
            <Partner />
          ) : this.PORTAL_TYPE === 'client' ? (
            <Client />
          ) : null}
        </div>
      </div>
    );
  }
}

export default Dashboard;
