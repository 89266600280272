import React, { Component } from 'react';
import WebApps from '../../spa-master/assets/scripts/master';
import buildCard from '../../spa-master/components/cards';
import Form from '../../spa-master/components/Form';
import Modal from '../../spa-master/components/Modal';
import { handleFormButtonAction } from '../../spa-master/components/_Utilities';

const { isset } = WebApps.Mappings;
const { makeApiRequest } = WebApps.Requests;

class Datasource extends Component {
  constructor(props) {
    super(props);

    this.RE_INITIALIZE = props.RE_INITIALIZE;
    this.SET_GLOBAL_STATE = props.SET_GLOBAL_STATE;

    this.state = {
      datasourceId: props.match.params.datasource,
      datasource: {},
      datasources: [],
    };
  }

  componentDidMount() {
    this.RE_INITIALIZE();
    this.SET_GLOBAL_STATE({
      PAGE_OPTIONS: {
        pageInfo: { pageTitle: 'Datasource' },
        pageActions: [
          {
            name: 'Create Group',
            modal: 'create_group',
            btnClass: 'primary',
          },
          {
            name: 'Create Channel',
            modal: 'create_channel',
            btnClass: 'primary',
          },
          {
            name: 'Create Tab',
            modal: 'create_tab',
            btnClass: 'primary',
          },
        ],
      },
    });

    if (!Number.isInteger(parseInt(this.state.datasourceId))) {
      window.Alert('MISSING_RESOURCE', 'Datasource Not Found', '/datasources');
    }

    this.fetchPageData();
    this.buildGroupsTable();
    this.buildChannelsTable();
    this.buildTabsTable();
  }

  SET_STATE = (update) => {
    this.setState(update);
  };

  FORM_HANDLER = (e) => {
    handleFormButtonAction(e, this.state, this.fetchPageData);
  };

  /**
   * These functions can happen in any order, we just need to return
   * a promise to force the table refresh to wait
   */
  fetchPageData = () => {
    makeApiRequest(window.apiClient.datasourcesGet)
      .then((data) => {
        this.setState({
          datasources: data.data,
        });
      })
      .catch(function (err) {
        console.log(err);
        window.Alert('error', 'Unable to fetch data!');
      });

    return makeApiRequest(window.apiClient.datasourcesDatasourceIdGet, {
      datasourceId: this.state.datasourceId,
    }).then((data) => {
      if (Object.keys(isset(data, 'data', {})).length > 0) {
        this.setState({
          datasource: data.data,
        });
      } else {
        window.Alert('error', 'Datasource Not Found');
      }
    });
  };

  getGroups = (callback) => {
    makeApiRequest(window.apiClient.datasourcesDatasourceIdGet, {
      datasourceId: this.state.datasourceId,
    }).then((data) => {
      if (Object.keys(isset(data, 'data', {})).length > 0) {
        callback(WebApps.Mappings.isset(data.data, 'groups', []));
      } else {
        callback([]);
      }
    });
  };

  buildGroupsTable = () => {
    WebApps.Datatables.buildTable(
      'group-table',
      {
        columns: [
          {
            source: 'name',
            title: 'Name',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'alias',
            title: 'Friendly Name',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'record_identifier',
            title: 'Identifier',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'email_address_prefix',
            title: 'Email Prefix',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'email_padding_configuration',
            title: 'Email Standardization',
            align: 'center',
            visible: false,
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'owner_email_address',
            title: 'Owners',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'member_email_address',
            title: 'Members',
            align: 'center',
            visible: false,
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'allow_external_senders',
            title: 'Allow External Senders',
            align: 'center',
            visible: false,
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'auto_subscribe_new_members',
            title: 'Subscribe New Members',
            align: 'center',
            visible: false,
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'id',
            title: 'Manage',
            align: 'center',
            render: function (data) {
              return data
                ? '<a data-toggle="modal" data-type="group" class="edit-group" data-record-id="' +
                    data +
                    '" href="#modal_edit_group_' +
                    data +
                    '">View / Edit</a>'
                : '-';
            },
          },
        ],
        recordType: 'Groups',
      },
      this.getGroups
    );
  };

  getChannels = (callback) => {
    makeApiRequest(window.apiClient.datasourcesDatasourceIdGet, {
      datasourceId: this.state.datasourceId,
    }).then((data) => {
      if (Object.keys(isset(data, 'data', {})).length > 0) {
        callback(WebApps.Mappings.isset(data.data, 'channels', []));
      } else {
        callback([]);
      }
    });
  };

  buildChannelsTable = () => {
    WebApps.Datatables.buildTable(
      'channel-table',
      {
        columns: [
          {
            source: 'name',
            title: 'Name',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'alias',
            title: 'Friendly Name',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'record_identifier',
            title: 'Identifier',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'team_identifier',
            title: 'Parent',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'remove_wiki',
            title: 'Remove Wiki Tab',
            align: 'center',
            render: function (data) {
              return data ? (data === 1 ? 'Yes' : 'No') : '-';
            },
          },
          {
            source: 'id',
            title: 'Manage',
            align: 'center',
            render: function (data) {
              return data
                ? '<a data-toggle="modal" data-type="channel" class="edit-channel" data-record-id="' +
                    data +
                    '" href="#modal_edit_channel_' +
                    data +
                    '">View / Edit</a>'
                : '-';
            },
          },
        ],
        recordType: 'Channels',
      },
      this.getChannels
    );
  };

  getTabs = (callback) => {
    makeApiRequest(window.apiClient.datasourcesDatasourceIdGet, {
      datasourceId: this.state.datasourceId,
    }).then((data) => {
      if (Object.keys(isset(data, 'data', {})).length > 0) {
        callback(WebApps.Mappings.isset(data.data, 'tabs', []));
      } else {
        callback([]);
      }
    });
  };

  buildTabsTable = () => {
    WebApps.Datatables.buildTable(
      'tab-table',
      {
        columns: [
          {
            source: 'name',
            title: 'Name',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'alias',
            title: 'Friendly Name',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'record_identifier',
            title: 'Identifier',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'channel_identifier',
            title: 'Parent',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'id',
            title: 'Manage',
            align: 'center',
            render: function (data) {
              return data
                ? '<a data-toggle="modal" data-type="tab" class="edit-tab" data-record-id="' +
                    data +
                    '" href="#modal_edit_tab_' +
                    data +
                    '">View / Edit</a>'
                : '-';
            },
          },
        ],
        recordType: 'Tabs',
      },
      this.getTabs
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className='row mt-3'>
          <div className='col-6'>
            {buildCard(
              'new-resume-template',
              'Datasource',
              <div className='card-body'>
                {Object.keys(this.state.datasource).length > 0 ? (
                  <React.Fragment>
                    <Form
                      dataId={'update_datasource_' + this.state.datasourceId}
                      SET_STATE={this.SET_STATE}
                      inputs={[
                        {
                          name: 'datasource_id',
                          type: 'hidden',
                          value: this.state.datasourceId,
                        },
                        {
                          label: 'Name',
                          name: 'description',
                          type: 'text',
                          value: this.state.datasource.datasource.description,
                        },
                        {
                          label: 'Filename',
                          type: 'text',
                          name: 'filename',
                          value: this.state.datasource.datasource.filename,
                        },
                      ]}
                      footer={{
                        sdkPath: 'datasourcesDatasourceId',
                        objectType: 'datasource',
                        objectKey: this.state.datasourceId,
                        objectAction: 'update',
                        submitBtn: {
                          handler: this.FORM_HANDLER,
                        },
                        deleteBtn: {
                          redirect: 'datasources',
                          handler: this.FORM_HANDLER,
                        },
                      }}
                    />
                  </React.Fragment>
                ) : null}
              </div>
            )}
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-12'>
            {buildCard(
              'group-task',
              'Groups',
              <div className='card-body'>
                <table id='group-table'></table>
              </div>,
              { align: 'left' }
            )}
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-12'>
            {buildCard(
              'tv-antenna',
              'Channels',
              <div className='card-body'>
                <table id='channel-table'></table>
              </div>,
              { align: 'left' }
            )}
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-12'>
            {buildCard(
              'add-tab',
              'Tabs',
              <div className='card-body'>
                <table id='tab-table'></table>
              </div>,
              { align: 'left' }
            )}
          </div>
        </div>
        {/* BASE EDIT MODALS */}
        {isset(this.state.datasource, 'groups', []).length > 0
          ? this.state.datasource.groups.map((group) => (
              <Modal
                objectKey={group.id}
                objectType='group'
                objectAction='edit'
                body={{
                  form: {
                    SET_STATE: this.SET_STATE,
                    data: group,
                    inputs: [
                      {
                        name: 'datasource_id',
                        type: 'hidden',
                        value: this.state.datasourceId,
                      },
                      {
                        name: 'group_id',
                        type: 'hidden',
                        value: group.id,
                      },
                      {
                        label: 'Name',
                        name: 'name',
                        type: 'text',
                        value: group.name,
                      },
                      {
                        label: 'Alias',
                        name: 'alias',
                        type: 'text',
                        value: group.alias,
                      },
                      {
                        label: 'Record Identifier',
                        name: 'record_identifier',
                        type: 'text',
                        value: group.record_identifier,
                      },
                      {
                        label: 'Email Prefix',
                        name: 'email_address_prefix',
                        type: 'text',
                        value: group.email_address_prefix,
                      },
                      {
                        label: 'Email Standardization',
                        name: 'email_padding_configuration',
                        type: 'text',
                        visible: false,
                        value: group.email_padding_configuration,
                      },
                      {
                        label: 'Owner Email',
                        name: 'owner_email_address',
                        type: 'text',
                        value: group.owner_email_address,
                      },
                      {
                        label: 'Member Email',
                        name: 'member_email_address',
                        type: 'text',
                        required: false,
                        visible: false,
                        value: group.member_email_address,
                      },
                      {
                        label: 'Allow External Senders',
                        name: 'allow_external_senders',
                        type: 'text',
                        required: false,
                        visible: false,
                        value: group.allow_external_senders,
                      },
                      {
                        label: 'Subscribe New Members',
                        name: 'auto_subscribe_new_members',
                        type: 'text',
                        required: false,
                        visible: false,
                        value: group.auto_subscribe_new_members,
                      },
                    ],
                  },
                }}
                opts={{ size: 'lg' }}
                footer={{
                  onSubmit: this.FORM_HANDLER,
                  onDelete: this.FORM_HANDLER,
                  actionText: 'Update',
                  tableToRefresh: 'group',
                  sdkPath: 'datasourcesDatasourceIdGroupsGroupId',
                }}
              ></Modal>
            ))
          : null}
        {isset(this.state.datasource, 'channels', []).length > 0
          ? this.state.datasource.channels.map((channel) => (
              <Modal
                objectKey={channel.id}
                objectType='channel'
                objectAction='edit'
                body={{
                  form: {
                    SET_STATE: this.SET_STATE,
                    data: channel,
                    inputs: [
                      {
                        name: 'datasource_id',
                        type: 'hidden',
                        value: this.state.datasourceId,
                      },
                      {
                        name: 'team_id',
                        type: 'hidden',
                        value: channel.team_id,
                      },
                      {
                        name: 'channel_id',
                        type: 'hidden',
                        value: channel.id,
                      },
                      {
                        label: 'Name',
                        type: 'text',
                        name: 'name',
                        value: channel.name,
                      },
                      {
                        label: 'Alias',
                        type: 'text',
                        name: 'alias',
                        value: channel.alias,
                      },
                      {
                        label: 'Team Identifier',
                        type: 'text',
                        name: 'team_identifier',
                        value: channel.team_identifier,
                      },
                      {
                        label: 'Record Identifier',
                        type: 'text',
                        name: 'record_identifier',
                        value: channel.record_identifier,
                      },
                      {
                        name: 'remove_wiki',
                        label: 'Remove Wiki Tab',
                        type: 'dropdown',
                        required: false,
                        value: channel.remove_wiki,
                        options: {
                          data: [
                            {
                              id: 0,
                              value: 'No, Do Not Delete Wiki Tab',
                            },
                            {
                              id: 1,
                              value: 'Yes, Remove the Wiki Tab',
                            },
                          ],
                          default: channel.remove_wiki,
                          key: 'id',
                          label: 'value',
                        },
                      },
                    ],
                  },
                }}
                opts={{ size: 'lg' }}
                footer={{
                  onSubmit: this.FORM_HANDLER,
                  onDelete: this.FORM_HANDLER,
                  cancelText: 'Cancel Update',
                  tableToRefresh: 'channel',
                  sdkPath:
                    'datasourcesDatasourceIdTeamsTeamIdChannelsChannelId',
                }}
              ></Modal>
            ))
          : null}
        {isset(this.state.datasource, 'tabs', []).length > 0
          ? this.state.datasource.tabs.map((tab) => {
              /**
               * We are including the team_id as null because it is required
               * based on the api resource configuration but it is not actually
               * used. If we need it, we will make an api call here to get the
               * team id for the tab.channel_id
               *
               * We will however, need to add a team_id to the current tab
               */
              tab['team_id'] = 0;
              return (
                <Modal
                  objectKey={tab.id}
                  objectType='tab'
                  objectAction='edit'
                  body={{
                    form: {
                      SET_STATE: this.SET_STATE,
                      data: tab,
                      inputs: [
                        {
                          name: 'datasource_id',
                          type: 'hidden',
                          value: this.state.datasourceId,
                        },
                        {
                          name: 'team_id',
                          type: 'hidden',
                          value: 0,
                        },
                        {
                          name: 'channel_id',
                          type: 'hidden',
                          value: tab.channel_id,
                        },
                        {
                          name: 'tab_id',
                          type: 'hidden',
                          value: tab.id,
                        },
                        {
                          label: 'Name',
                          type: 'text',
                          value: tab.name,
                          name: 'name',
                        },
                        {
                          label: 'Alias',
                          type: 'text',
                          value: tab.alias,
                          name: 'alias',
                        },
                        {
                          label: 'Channel Identifier',
                          type: 'text',
                          name: 'channel_identifier',
                          value: tab.channel_identifier,
                        },
                        {
                          label: 'Record Identifier',
                          type: 'text',
                          name: 'record_identifier',
                          value: tab.record_identifier,
                        },
                        {
                          label: 'Type',
                          type: 'text',
                          placeholder: 'web',
                          name: 'type',
                          value: tab.type,
                        },
                        {
                          name: 'type',
                          label: 'Type',
                          type: 'dropdown',
                          required: false,
                          value: tab.type,
                          options: {
                            data: [
                              {
                                id: 'web',
                                value: 'Web',
                              },
                            ],
                            default: tab.type,
                            key: 'id',
                            label: 'value',
                          },
                        },
                        {
                          label: 'Configuration',
                          type: 'text',
                          name: 'configuration',
                          value: JSON.stringify(tab.configuration),
                        },
                      ],
                    },
                  }}
                  opts={{ size: 'lg' }}
                  footer={{
                    onSubmit: this.FORM_HANDLER,
                    onDelete: this.FORM_HANDLER,
                    cancelText: 'Cancel Update',
                    tableToRefresh: 'tab',
                    sdkPath:
                      'datasourcesDatasourceIdTeamsTeamIdChannelsChannelIdTabsTabId',
                  }}
                ></Modal>
              );
            })
          : null}
        {/* CREATE MODALS */}
        {this.state.datasources.length > 0 &&
        Object.keys(this.state.datasource).length > 0 ? (
          <React.Fragment>
            <Modal
              objectType='group'
              objectAction='create'
              body={{
                form: {
                  SET_STATE: this.SET_STATE,
                  inputs: [
                    {
                      name: 'datasource_id',
                      type: 'hidden',
                      value: this.state.datasourceId,
                    },
                    {
                      label: 'Name',
                      name: 'name',
                      type: 'text',
                    },
                    {
                      label: 'Alias',
                      name: 'alias',
                      type: 'text',
                    },
                    {
                      label: 'Record Identifier',
                      name: 'record_identifier',
                      type: 'text',
                      helpText: 'Friendly name for this set of groups',
                    },
                    {
                      label: 'Email Prefix',
                      name: 'email_address_prefix',
                      type: 'text',
                    },
                    {
                      label: 'Email Standardization',
                      name: 'email_padding_configuration',
                      type: 'text',
                      visible: false,
                    },
                    {
                      label: 'Owner Email',
                      name: 'owner_email_address',
                      type: 'text',
                    },
                    {
                      label: 'Member Email',
                      name: 'member_email_address',
                      type: 'text',
                      required: false,
                      visible: false,
                    },
                    {
                      label: 'Allow External Senders',
                      name: 'allow_external_senders',
                      type: 'text',
                      visible: false,
                      required: false,
                    },
                    {
                      label: 'Subscribe New Members',
                      name: 'auto_subscribe_new_members',
                      type: 'text',
                      visible: false,
                      required: false,
                    },
                  ],
                },
              }}
              opts={{ size: 'lg' }}
              footer={{
                onSubmit: this.FORM_HANDLER,
                cancelText: 'Cancel',
                tableToRefresh: 'group',
                sdkPath: 'datasourcesDatasourceIdGroups',
              }}
            ></Modal>
            <Modal
              objectType='channel'
              objectAction='create'
              body={{
                form: {
                  SET_STATE: this.SET_STATE,
                  inputs: [
                    {
                      name: 'datasource_id',
                      type: 'hidden',
                      value: this.state.datasourceId,
                    },
                    /**
                     * What we need here is to add a nested dropdown selector like ITR content rule board/status
                     * WebApps.Arrays.makeSingleDem(
                          this.state.datasources,
                          'datasource'
                        )
                        Ours will say datasource/team
                     */
                    {
                      name: 'team_id',
                      label: 'Team Link',
                      type: 'dropdown',
                      value: WebApps.Arrays.makeSingleDem(
                        this.state.datasources,
                        'groups',
                        { parentId: 'datasource_id' }
                      )[0]['id'],
                      options:
                        WebApps.Arrays.makeSingleDem(
                          this.state.datasources,
                          'groups',
                          { parentId: 'datasource_id' }
                        ).length > 0
                          ? {
                              data: WebApps.Arrays.makeSingleDem(
                                this.state.datasources,
                                'groups',
                                { parentId: 'datasource_id' }
                              ),
                              default: WebApps.Arrays.makeSingleDem(
                                this.state.datasources,
                                'groups',
                                { parentId: 'datasource_id' }
                              )[0]['id'],
                              key: 'id',
                              label: 'alias',
                            }
                          : {},
                    },
                    {
                      label: 'Name',
                      name: 'name',
                      type: 'text',
                    },
                    {
                      label: 'Alias',
                      name: 'alias',
                      type: 'text',
                    },
                    {
                      label: 'Team Identifier',
                      name: 'team_identifier',
                      type: 'text',
                    },
                    {
                      label: 'Record Identifier',
                      name: 'record_identifier',
                      type: 'text',
                    },
                    {
                      name: 'remove_wiki',
                      label: 'Remove Wiki Tab',
                      type: 'dropdown',
                      required: false,
                      options: {
                        data: [
                          {
                            id: 0,
                            value: 'No, Do Not Delete Wiki Tab',
                          },
                          {
                            id: 1,
                            value: 'Yes, Remove the Wiki Tab',
                          },
                        ],
                        default: 1,
                        key: 'id',
                        label: 'value',
                      },
                    },
                  ],
                },
              }}
              opts={{ size: 'lg' }}
              footer={{
                onSubmit: this.FORM_HANDLER,
                cancelText: 'Cancel',
                tableToRefresh: 'channel',
                sdkPath: 'datasourcesDatasourceIdTeamsTeamIdChannels',
              }}
            ></Modal>
            <Modal
              objectType='tab'
              objectAction='create'
              body={{
                form: {
                  SET_STATE: this.SET_STATE,
                  inputs: [
                    {
                      name: 'datasource_id',
                      type: 'hidden',
                      value: this.state.datasourceId,
                    },
                    {
                      name: 'team_id',
                      type: 'hidden',
                      value: 0,
                    },
                    {
                      name: 'channel_id',
                      label: 'Channel Link',
                      type: 'dropdown',
                      value: WebApps.Arrays.makeSingleDem(
                        this.state.datasources,
                        'channels',
                        { parentId: 'datasource_id' }
                      )[0]['id'],
                      options:
                        WebApps.Arrays.makeSingleDem(
                          this.state.datasources,
                          'channels',
                          { parentId: 'datasource_id' }
                        ).length > 0
                          ? {
                              data: WebApps.Arrays.makeSingleDem(
                                this.state.datasources,
                                'channels',
                                { parentId: 'datasource_id' }
                              ),
                              default: WebApps.Arrays.makeSingleDem(
                                this.state.datasources,
                                'channels',
                                { parentId: 'datasource_id' }
                              )[0]['id'],
                              key: 'id',
                              label: 'alias',
                            }
                          : {},
                    },
                    {
                      label: 'Name',
                      type: 'text',
                      name: 'name',
                    },
                    {
                      label: 'Alias',
                      type: 'text',
                      name: 'alias',
                    },
                    {
                      label: 'Channel Identifier',
                      name: 'channel_identifier',
                      type: 'text',
                    },
                    {
                      label: 'Record Identifier',
                      name: 'record_identifier',
                      type: 'text',
                    },
                    {
                      name: 'type',
                      label: 'Type',
                      type: 'dropdown',
                      required: false,
                      options: {
                        data: [
                          {
                            id: 'web',
                            value: 'Web',
                          },
                        ],
                        default: 'web',
                        key: 'id',
                        label: 'value',
                      },
                    },
                    {
                      label: 'Configuration',
                      type: 'text',
                      name: 'configuration',
                    },
                  ],
                },
              }}
              opts={{ size: 'lg' }}
              footer={{
                onSubmit: this.FORM_HANDLER,
                cancelText: 'Cancel',
                tableToRefresh: 'tab',
                sdkPath:
                  'datasourcesDatasourceIdTeamsTeamIdChannelsChannelIdTabs',
              }}
            ></Modal>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

export default Datasource;
