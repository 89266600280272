/**
 *
 * Page to show the development tasks/tickets in Jira
 *
 * Also allow devs to create new functions and function groups
 */
import React, { Component } from 'react';
import buildCard from '../../../components/cards';

class Development extends Component {
  constructor(props) {
    super(props);

    this.state = {
      RE_INITIALIZE: props.RE_INITIALIZE,
      SET_GLOBAL_STATE: props.SET_GLOBAL_STATE,
      tasks: [],
    };
  }

  componentDidMount() {
    this.state.SET_GLOBAL_STATE({
      PAGE_OPTIONS: {
        pageInfo: { pageTitle: 'Development' },
        pageActions: [
          {
            name: 'Create Function Group',
            modal: 'create_function_group',
            btnClass: 'primary',
          },
        ],
      },
    });
    /*
    WebApps.Requests.makeApiRequest(window.apiClient.developmentTasksGet)
      .then((data) => {
        this.SET_STATE({
          tasks: data.data,
        });
      })
      .catch((err) => {
        window.Alert('error', err);
      });
      */
  }

  SET_STATE = (update) => {
    this.setState(update);
  };

  render() {
    return (
      <div className='row mt-3'>
        <div className='col-12'>
          {buildCard(
            'handshake',
            'Tasks',
            this.state.tasks.length > 0 ? (
              <div className='card-body'>
                <p>Viewing Tasks</p>
              </div>
            ) : null
          )}
        </div>
      </div>
    );
  }
}

export default Development;
