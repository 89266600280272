import React, { Component } from 'react';
import HEADER from './spa-master/ui-templates/header.jsx';

import Cookies from 'universal-cookie';

import {
  Dashboard,
  Account,
  Users,
  Partners,
  Lock,
} from './spa-master/ui-templates/pages';
import {
  Datasources,
  Datasource,
  Jobs,
  Job,
  Configuration,
  Clients,
} from './pages';
import Controller from './spa-master/controller/Controller';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//Datatable Modules
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

import alert from './spa-master/components/Alert';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      IS_AUTHORIZED: false,
      PROCESSING: true,
      CONTROLLER: window.location.href.includes('controller'),
      USER_INFO: {},
      USER_ID: null,
      CLIENT_ID: null,
      PARTNER_ID: null,
      PORTAL_ACCESS: [],
      PORTAL_TYPE: null,
      PORTAL_EMULATION_ACTIVE: false,
      PAGE_OPTIONS: {},
    };
  }

  componentDidMount() {
    this.INITIALIZE();
  }

  /**
   * Here we will define our global variables
   */
  INITIALIZE = () => {
    // This init is no longer working again...
    //window.App.init();
    window.Alert = alert;

    window.apiClient = window.apigClientFactory.newClient();

    window.cookies = new Cookies();
  };

  /**
   * Function to pass to all children to allow for
   * global state setting (i.e. adjusting the header)
   */
  SET_GLOBAL_STATE = (update) => {
    this.setState(update);
  };

  render() {
    /**
     * The initial design was to wrap the entire application inside of the lock
     * In this way, the header and the routes become children of the lock and we
     * can handle all access through the Lock. However, the problem is that the children
     * will only re-render if there is some specific change to the state, which initially
     * we could not pinpoint.
     *
     * This was the simplest alternative. All page reloads will require a reauth where traversing
     * through the preloaded pages will not. We can easily add a check to the INITIALIZE function
     * that can verify the user's token and only change the state if the user is not authorized.
     */
    return (!this.state.IS_AUTHORIZED || this.state.PROCESSING) &&
      !this.state.CONTROLLER &&
      !window.location.href.includes('login') ? (
      <Router>
        <Switch>
          <Route
            path='/'
            render={(props) => (
              <Lock
                SET_GLOBAL_STATE={this.SET_GLOBAL_STATE}
                RE_INITIALIZE={this.INITIALIZE}
                PROCESSING={this.state.PROCESSING}
                IS_AUTHORIZED={this.state.IS_AUTHORIZED}
                {...props}
              />
            )}
          />
        </Switch>
      </Router>
    ) : (
      <Router>
        <HEADER GLOBAL_STATE={this.state}>
          <Switch>
            <Route
              exact
              path='/account'
              render={(props) => (
                <Account
                  SET_GLOBAL_STATE={this.SET_GLOBAL_STATE}
                  RE_INITIALIZE={this.INITIALIZE}
                />
              )}
            />
            <Route
              exact
              path='/users'
              render={(props) => (
                <Users
                  SET_GLOBAL_STATE={this.SET_GLOBAL_STATE}
                  RE_INITIALIZE={this.INITIALIZE}
                />
              )}
            />
            <Route
              exact
              path='/users/roles'
              render={(props) => (
                <Users
                  SET_GLOBAL_STATE={this.SET_GLOBAL_STATE}
                  RE_INITIALIZE={this.INITIALIZE}
                />
              )}
            />
            <Route
              exact
              path='/datasources'
              render={(props) => (
                <Datasources
                  SET_GLOBAL_STATE={this.SET_GLOBAL_STATE}
                  RE_INITIALIZE={this.INITIALIZE}
                />
              )}
            />
            <Route
              path='/datasources/:datasource'
              render={(props) => (
                <Datasource
                  SET_GLOBAL_STATE={this.SET_GLOBAL_STATE}
                  RE_INITIALIZE={this.INITIALIZE}
                  {...props}
                />
              )}
            />

            <Route
              exact
              path='/jobs'
              render={(props) => (
                <Jobs
                  SET_GLOBAL_STATE={this.SET_GLOBAL_STATE}
                  RE_INITIALIZE={this.INITIALIZE}
                />
              )}
            />
            <Route
              path='/jobs/:job'
              render={(props) => (
                <Job
                  SET_GLOBAL_STATE={this.SET_GLOBAL_STATE}
                  RE_INITIALIZE={this.INITIALIZE}
                  {...props}
                />
              )}
            />
            <Route
              path='/configuration'
              render={(props) => (
                <Configuration
                  SET_GLOBAL_STATE={this.SET_GLOBAL_STATE}
                  RE_INITIALIZE={this.INITIALIZE}
                />
              )}
            />
            <Route
              path='/partners'
              render={(props) => (
                <Partners
                  SET_GLOBAL_STATE={this.SET_GLOBAL_STATE}
                  RE_INITIALIZE={this.INITIALIZE}
                  {...props}
                />
              )}
            />
            <Route
              path='/clients'
              render={(props) => (
                <Clients
                  SET_GLOBAL_STATE={this.SET_GLOBAL_STATE}
                  RE_INITIALIZE={this.INITIALIZE}
                  {...props}
                />
              )}
            />

            <Route
              path='/controller'
              render={(props) => (
                <Controller
                  SET_GLOBAL_STATE={this.SET_GLOBAL_STATE}
                  RE_INITIALIZE={this.INITIALIZE}
                  GLOBAL_STATE={this.state}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path='/'
              render={(props) => (
                <Dashboard
                  SET_GLOBAL_STATE={this.SET_GLOBAL_STATE}
                  RE_INITIALIZE={this.INITIALIZE}
                  PORTAL_TYPE={this.state.PORTAL_TYPE}
                  {...props}
                />
              )}
            />
          </Switch>
        </HEADER>
      </Router>
    );
  }
}

export default App;
