import React, { Component } from 'react';
import buildCard from '../../../components/cards';

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PAGE_OPTIONS: props.PAGE_OPTIONS,
    };
  }

  componentDidMount() {
    this.state.PAGE_OPTIONS({
      pageInfo: { pageTitle: 'Account' },
    });
  }

  render() {
    return (
      <div className='row mt-3'>
        <div className='col-12'>
          {buildCard(
            'user',
            'Edit Profile',
            <React.Fragment>
              <p>Just pretend that this page works!</p>
              <p>I have nothing more to say!</p>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Account;
