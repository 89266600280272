import React, { Component } from 'react';
import buildCard from '../../spa-master/components/cards';
import WebApps from '../../spa-master/assets/scripts/master';
import Modal from '../../spa-master/components/Modal';
import { handleFormButtonAction } from '../../spa-master/components/_Utilities';

const { isset } = WebApps.Mappings;
const { makeApiRequest } = WebApps.Requests;

class Datasources extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SET_GLOBAL_STATE: props.SET_GLOBAL_STATE,
    };
  }

  componentDidMount() {
    this.state.SET_GLOBAL_STATE({
      PAGE_OPTIONS: {
        pageInfo: { pageTitle: 'Datasources' },
        pageActions: [
          {
            name: 'Create Data Source',
            modal: 'create_datasource',
            btnClass: 'primary',
          },
        ],
      },
    });

    this.buildDatasourceTable();
  }

  SET_STATE = (update, callback = null) => {
    this.setState(update, callback ? callback() : callback);
  };

  FORM_HANDLER = (e) => {
    handleFormButtonAction(e, this.state, null, null, true, true);
  };

  getDatasources = (callback) => {
    makeApiRequest(window.apiClient.datasourcesGet)
      .then((data) => {
        callback(isset(data, 'data', []).length > 0 ? data.data : []);
      })
      .catch(function (err) {
        console.log(err);
        window.Alert('error', 'Unable to fetch data!');
      });
  };

  buildDatasourceTable = () => {
    WebApps.Datatables.buildTable(
      'datasource-table',
      {
        columns: [
          {
            source: 'datasource',
            title: 'ID',
            align: 'center',
            render: function (data) {
              return isset(data, 'id', '-');
            },
          },
          {
            source: 'datasource',
            title: 'Description',
            align: 'center',
            render: function (data) {
              return isset(data, 'description', '-');
            },
          },
          {
            source: 'datasource',
            title: 'Filename',
            align: 'center',
            render: function (data) {
              return isset(data, 'filename', '-');
            },
          },
          {
            source: 'datasource',
            title: 'Manage',
            align: 'center',
            render: function (data) {
              return isset(data, 'id')
                ? '<a href="/datasources/' + data.id + '">View / Edit</a>'
                : '-';
            },
          },
        ],
        recordType: 'Datasources',
      },
      this.getDatasources
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className='row mt-3'>
          <div className='col-12'>
            {buildCard(
              'new-resume-template',
              'Datasources',
              <div className='card-body'>
                <table id='datasource-table'></table>
              </div>,
              { align: 'left' }
            )}
          </div>
        </div>
        <Modal
          objectAction='create'
          objectType='datasource'
          body={{
            content: (
              <React.Fragment>
                <p>
                  Data Sources are powerful csv-based templates that offer
                  simple, flexible, Team's resource generation.
                </p>

                <p>
                  To get started, devise a name for your data source. A data
                  source's name should reflect, as comprehensively as desired,
                  the set of resources that will be created from it.
                </p>
              </React.Fragment>
            ),
            form: {
              SET_STATE: this.SET_STATE,
              inputs: [
                {
                  label: 'Name',
                  type: 'text',
                  name: 'description',
                },
                {
                  label: 'Filename',
                  type: 'text',
                  name: 'filename',
                },
              ],
            },
          }}
          opts={{ size: 'md' }}
          footer={{
            onSubmit: this.FORM_HANDLER,
            tableToRefresh: 'datasource',
            sdkPath: 'datasources',
          }}
        ></Modal>
      </React.Fragment>
    );
  }
}

export default Datasources;
