import React, { Component } from 'react';
import WebApps from '../assets/scripts/master';

const { isset } = WebApps.Mappings;

export default class Button extends Component {
  constructor(props) {
    super(props);

    const { options } = props;
    this.state = {
      id: isset(options, 'id', ''),
      loading: false,
      loader: isset(options, 'loader', true),
      dataId: isset(options, 'dataId', ''),
      modalToShow: isset(options, 'modalToShow', null),
      sdkPath: isset(options, 'sdkPath', null),
      redirect: isset(options, 'redirect', null),
      tableToRefresh: isset(options, 'tableToRefresh', null),
      btnClass: 'btn m-2 btn-',
      style: isset(
        options,
        'btnStyle',
        ''
      ) /* outline | primary, secondary, success, danger, warning, info, light, dark, link */,
      type: isset(options, 'type', 'submit') /* button, submit, reset */,
      label: isset(options, 'label', 'Submit'),
      size: 'btn-' + isset(options, 'size', 'md') /* btn-sm, lg */,
      state: isset(options, 'state', 'Search') /* enabled, disabled*/,
      //align: isset(options, 'align', null) /* right - justify-content-md-end */,
      handler: isset(options, 'handler', null),
      value: isset(options, 'value', null),
      modal: isset(options, 'modal', false),
      visible: isset(options, 'visible', true),
    };
  }

  stopLoader = () => {
    // Stop the loader two seconds after the job actually completed to make it show and make it smoother
    setTimeout(() => this.setState({ loading: false }), 2000);
  };

  render() {
    const {
      id,
      loading,
      loader,
      dataId,
      sdkPath,
      redirect,
      modalToShow,
      tableToRefresh,
      btnClass,
      style,
      type,
      label,
      visible,
      size,
      //align,
      modal,
      state,
      handler,
      value,
    } = this.state;

    switch (type) {
      case 'control':
        return (
          <a href={'/controller?action=' + value}>
            <button
              type='button'
              className={btnClass + style + ' ' + size + ' ' + state}
            >
              {label}
            </button>
          </a>
        );
      case 'delete':
        return (
          <button
            id={id.replace('edit-', 'delete-').replace('update-', 'delete-')}
            type='button'
            data-id={dataId}
            data-sdk={sdkPath}
            data-modaltoshow={modalToShow}
            data-redirect={redirect}
            data-tabletorefresh={tableToRefresh}
            data-dismiss={modal ? 'modal' : null}
            className={btnClass + 'danger'}
            onClick={(e) => {
              this.setState({ loading: true }, () => {
                if (handler) {
                  handler(e);
                }
              });
            }}
          >
            {label}
          </button>
        );
      case 'cancel':
        return (
          <button
            id='test-cancel'
            className={btnClass}
            type='button'
            data-dismiss={modal ? 'modal' : null}
          >
            {label}
          </button>
        );
      default:
        return visible ? (
          <button
            id={id}
            value={value}
            data-id={dataId}
            data-sdk={sdkPath}
            data-modaltoshow={modalToShow}
            data-redirect={redirect}
            data-tabletorefresh={tableToRefresh}
            data-dismiss={modal ? 'modal' : null}
            onClick={(e) => {
              // We will store this stopload function in the event to hide the loader when the api call is done
              e.stopLoader = this.stopLoader;

              this.setState({ loading: true }, () => {
                handler(value ? value : e);
              });
            }}
            className={btnClass + style + ' ' + size + ' ' + state}
            type={type}
            disabled={loading && loader}
          >
            {label}
            {loading && loader ? WebApps.Content.getSpinner() : null}
          </button>
        ) : null;
    }
  }
}
