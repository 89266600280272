import React, { Component } from 'react';
import WebApps from '../../spa-master/assets/scripts/master';
import buildCard from '../../spa-master/components/cards';

const { isset } = WebApps.Mappings;
const { makeApiRequest } = WebApps.Requests;

class Job extends Component {
  constructor(props) {
    super(props);

    this.SET_GLOBAL_STATE = props.SET_GLOBAL_STATE;

    this.state = {
      jobId: props.match.params.job,
    };
  }

  componentDidMount() {
    this.SET_GLOBAL_STATE({
      PAGE_OPTIONS: {
        pageInfo: { pageTitle: 'Job' },
        pageActions: [
          {
            name: 'Refresh',
            onClick: () => WebApps.Datatables.refreshTable('job'),
          },
        ],
      },
    });

    this.buildTaskTable();
  }

  getTasks = (callback) => {
    makeApiRequest(window.apiClient.jobsJobIdGet, {
      jobId: this.state.jobId,
    })
      .then((data) => {
        if (Object.keys(isset(data, 'data', {})).length > 0) {
          return data.data;
        } else {
          window.Alert('error', 'Job Not Found', '/jobs');
        }
      })
      .then((job) => {
        makeApiRequest(window.apiClient.jobsJobIdErrorsGet, {
          jobId: this.state.jobId,
        }).then((data) => {
          var errors = [];

          if (Object.keys(isset(data, 'data', {})).length > 0) {
            errors = WebApps.Arrays.rekey(data.data, 'task_id');
          }

          // Map any errors to it's respective task
          let tasks = WebApps.Mappings.isset(job, 'tasks', []).map((task) => {
            task['error_message'] = '';
            if (isset(errors, task.id, false)) {
              task['error_message'] = errors[task.id].message;
            }
            return task;
          });

          callback(tasks);
        });
      });
  };

  buildTaskTable = () => {
    WebApps.Datatables.buildTable(
      'task-table',
      {
        columns: [
          {
            source: 'id',
            title: 'Task #',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'record_identifier',
            title: 'Record Identifier',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'resource_type',
            title: 'Resource',
            align: 'center',
            render: function (data) {
              return data ? data : '-';
            },
          },
          {
            source: 'start_time',
            title: 'Started',
            align: 'center',
            render: function (data) {
              return data ? WebApps.Time.relativeToNow(data) : '-';
            },
          },
          {
            source: 'completed',
            title: 'Status',
            align: 'center',
            render: function (data, type, row) {
              return data
                ? 'Completed'
                : row[5]
                ? '<span title="' + row[5] + '">Error</span>'
                : 'In Progress';
            },
          },
          {
            source: 'error_message',
            title: 'Errors',
            align: 'center',
            visible: false,
          },
          {
            source: 'action',
            title: 'Action',
            align: 'center',
            render: function (data) {
              return data ? WebApps.Strings.ucword(data) : '-';
            },
          },
        ],
        recordType: 'Tasks',
      },
      this.getTasks
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className='row mt-3'>
          <div className='col-12'>
            {buildCard(
              'task',
              'Tasks',
              <div className='card-body'>
                <table id='task-table'></table>
              </div>,
              { align: 'left' }
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Job;
